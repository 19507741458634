








import {Component, Prop, Vue} from 'vue-property-decorator';
import {i18n} from "@/bootstraps/locale";

@Component
export default class extends Vue {
  @Prop({default: i18n.t('変更を保存') }) private label!:string;
  @Prop({default: i18n.t('この操作をする権限がありません') }) private tooltip!:string;
  @Prop({default: 'c-button primary size-submit'}) private buttonClass!:string;
  @Prop({default: false}) private onlyAdmin!:boolean;

  private get canEdit(): boolean {
    if (this.onlyAdmin) {
      return this.$auth.user.isCompanyAdmin;
    } else {
      return this.$auth.user.isCompanyAdmin || this.$auth.user.isCompanyEditable;
    }
  }
}
