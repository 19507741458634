import RepositoryBase, {IFindOption} from "@/repositories/repository-base";
import CompanySpecCustomValueSettingEntity, {CompanySpecCustomValueSettingTargetResourceType} from "@/entities/company-spec-custom-value-setting-entity";
import PartnerIntraCustomValueSettingEntity from "@/entities/partner-intra-custom-value-setting-entity";

export class PartnerIntraCustomValueSettingRepository extends RepositoryBase<any> {
  protected companyId!:number;

  public constructor(companyId) {
    super();
    this.companyId = companyId;
    this.endpoint = `companies/${companyId}/partner-intra-custom-value-settings`
  }

  public async index(): Promise<PartnerIntraCustomValueSettingEntity[]>  {
    const res = await this.get(this.endpoint);
    return res.data.map(d => new PartnerIntraCustomValueSettingEntity(d));
  }
  public async save(data: PartnerIntraCustomValueSettingEntity[]) {
    const res = await this.put(this.endpoint, data);
    return res.data;
  }
}
