
















import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import CompanyEntity from "@/entities/company-entity";
import {CompanyRole} from "@/entities/user-entity";
import {CompanyMemberUpdateRequest} from "@/repositories/company-member-repository";

type Selection = {
  id: number;
  name: string;
};

@Component
export default class extends Vue {
  @Prop({required: true}) private company!:CompanyEntity;
  @Prop({required: true}) private value!: CompanyMemberUpdateRequest;

  private selectedDepartmentIds: number[] = [];

  private departmentSelections: Selection[] = [];

  private readonly ManagementTagId = -1;

  private managementTagAddedByAutomatically = false;
  @Watch('value.companyRole')
  private onCompanyRoleChanged() {
    if(this.isCompanyAdmin) {
      if (this.selectedDepartmentIds.includes(this.ManagementTagId)) return;
      this.selectedDepartmentIds.unshift(this.ManagementTagId);
      this.managementTagAddedByAutomatically = true;
      this.onDepartmentSelected(this.selectedDepartmentIds);
    } else {
      if (this.managementTagAddedByAutomatically && this.selectedDepartmentIds.includes(this.ManagementTagId)){
        this.selectedDepartmentIds.splice(this.selectedDepartmentIds.indexOf(this.ManagementTagId), 1);
        this.managementTagAddedByAutomatically = false;
        this.onDepartmentSelected(this.selectedDepartmentIds);
      }
    }
  }

  private get isCompanyAdmin(): boolean {
    return this.value.companyRole === CompanyRole.Admin;
  }

  private created() {
    this.departmentSelections =
      [{ id: this.ManagementTagId, name: this.$t('spec.common.management_tag'),}].concat(this.company.departments.map(d => ({id: d.id, name: d.name})));

    if (this.value.canAccessAllDeptResources) {
      this.selectedDepartmentIds = [this.ManagementTagId ].concat(this.value.departmentIds);
    } else {
      this.selectedDepartmentIds = this.value.departmentIds;
    }
  }

  private onDepartmentSelected(val:number[]) {
    if (val.includes(this.ManagementTagId)) {
      val.unshift(val.splice(val.indexOf(this.ManagementTagId), 1)[0]);
    }
    this.value.canAccessAllDeptResources = val.includes(this.ManagementTagId);
    this.value.departmentIds = val.filter(d => d !== this.ManagementTagId);
  }
}
