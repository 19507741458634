












import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class extends Vue {
  @Prop({required: false}) private title?: string;
}
