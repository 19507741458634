









































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

import {
  ValidatorRules
} from "@/entities/specs/spec-custom-value-entity";
import {
  CustomValueInnerTableModelType, makeModel
} from "@/views/spec/companies/components/CustomValue/custom-value-inner-table-composable";
import {CustomValueInputType, CustomValueInputTypeDict, ICustomValue } from '@/entities/interfaces/i-custom-value';
import {CustomValueInputTypeDictForSetting, ICustomValueSetting} from '@/entities/interfaces/i-custom-value-setting';
import CurrencySelect from "@/components/Form/CurrencySelect.vue";
import RowKey from "@/entities/concerns/rowkey";

@Component({
  components: {CurrencySelect}
})
export default class CustomValueInnerTable extends Vue {
  @Prop({required: true}) value!: ICustomValue[];
  @Prop({required: true}) addCustomValueRow!: () => ICustomValue;
  @Prop({required: true}) propName!: string;
  @Prop({required: false}) customValueSettings?: ICustomValueSetting[];

  private readonly CustomValueInputType = CustomValueInputType;
  private readonly CustomValueInputTypeDictForSetting = CustomValueInputTypeDictForSetting;
  private readonly CustomValueInputTypeDict = CustomValueInputTypeDict;
  private readonly ValidatorRules = ValidatorRules;

  public visible = false;

  private model: CustomValueInnerTableModelType[] = [];

  @Watch('model', { deep: true })
  private onModelChanged() {
    this.$emit('input', this.model);
  }

  private handleInputTypeChange(scope: { row: CustomValueInnerTableModelType }) {
    scope.row.value = ''; // 数字が入力された状態で入力形式を日付に変更した場合にエラーが出ないよう値をクリア
  }

  private created() {
    this.model = makeModel(this.value, this.customValueSettings);

    if (this.model.length === 0) {
      this.add();
    } else {
      this.visible = true;
    }
  }
  private row(scope: { row: CustomValueInnerTableModelType }): CustomValueInnerTableModelType {
    return scope.row;
  }


  private add() {
    const newModel = this.addCustomValueRow();
    this.model.push({
      ...newModel,
      $isFromSetting: false,
      $rowKey: RowKey.getRowKey(),
    });
  }

  private shouldShowValue2(row: CustomValueInnerTableModelType) {
    return row.inputType === CustomValueInputType.Money;
  }
  private getRuleForValue(row: CustomValueInnerTableModelType) {
    if (!row.title) return null;

    switch(row.inputType) {
      case CustomValueInputType.Number:
      case CustomValueInputType.Money:
        return ValidatorRules.value.number;
      case CustomValueInputType.Date:
        return ValidatorRules.value.date;
      case CustomValueInputType.Text:
        return ValidatorRules.value.text;
      case CustomValueInputType.Select:
        return ValidatorRules.value.select;
      default:
        return null;
    }
  }
}

