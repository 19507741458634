import { render, staticRenderFns } from "./MyPageBodySettingRow.vue?vue&type=template&id=74bdb5a3&scoped=true&"
import script from "./MyPageBodySettingRow.vue?vue&type=script&lang=ts&"
export * from "./MyPageBodySettingRow.vue?vue&type=script&lang=ts&"
import style0 from "./MyPageBodySettingRow.vue?vue&type=style&index=0&id=74bdb5a3&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74bdb5a3",
  null
  
)

export default component.exports