









import {Component, Prop, Vue} from 'vue-property-decorator';
import CompanyEntity from "@/entities/company-entity";
import {i18n} from "@/bootstraps/locale";

@Component
export default class extends Vue {
  @Prop({required: true}) private company!:CompanyEntity;
  @Prop({required: true}) private value!: { id:number|null, name:string }[];

  private departments: string[] = [];

  private mngLabel = i18n.t('spec.common.management_tag')

  private created() {
    this.departments = [this.mngLabel].concat(this.value.map(d => d.name));
  }

  private onInputDepartment(val:string[]) {
    const isAdd = val.length > this.value.length;
    if (isAdd) {
      this.value.push({
        id: null,
        name: val[val.length - 1]
      });
    } else {
      if (!val.includes(this.mngLabel)) {
        this.departments.unshift(this.mngLabel);
        return;
      }

      const removedTag = this.value.find(s => !val.includes(s.name))!;
      const registeredDept = this.company.departments.find(d => d.name  === removedTag.name);
      if (!registeredDept) {
        this.value.splice(this.value.findIndex(v => v.name === removedTag.name), 1)
        return;
      }

      if (registeredDept.memberCount > 0) {
        this.$alert(
          this.$t('mypage.company.department.delete_alert'),
          this.$t('common.of', { action: this.$t('削除'), item: `「${removedTag.name}」`}),
          {
            dangerouslyUseHTMLString: true,
            showCancelButton: false,
            customClass: 'danger title-center single-button',
            confirmButtonText: this.$t('閉じる'),
            confirmButtonClass: 'c-button danger no-focus',
          }
        );
        this.departments.push(removedTag.name); // 戻す
      } else {
        this.$confirm(
          this.$t('mypage.company.department.delete_confirm'),
          this.$t('common.of', { action: this.$t('削除'), item: `「${removedTag.name}」`}),
          {
            dangerouslyUseHTMLString: true,
            customClass: 'danger title-center',
            cancelButtonText: this.$t('キャンセル'),
            cancelButtonClass: 'c-button danger no-focus',
            confirmButtonText: this.$t('承諾して削除'),
            confirmButtonClass: 'c-button danger-inverse no-focus',
          }
        ).then(() => {
          this.value.splice(this.value.findIndex(v => v.id === registeredDept.id), 1)
        }).catch(err => {
          if (err !== 'cancel') throw err;
          this.departments.push(removedTag.name); // 戻す
        });
      }
    }
  }
}
