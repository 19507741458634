import RepositoryBase, {IFindOption} from "@/repositories/repository-base";
import CompanySpecCustomValueSettingEntity, {CompanySpecCustomValueSettingTargetResourceType} from "@/entities/company-spec-custom-value-setting-entity";

export class CompanySpecCustomValueSettingRepository extends RepositoryBase<CompanySpecCustomValueSettingEntity> {
  protected companyId!:number;

  public constructor(companyId) {
    super();
    this.companyId = companyId;
    this.endpoint = `companies/${companyId}/spec-custom-value-settings`
  }

  public async index(resourceType: CompanySpecCustomValueSettingTargetResourceType): Promise<CompanySpecCustomValueSettingEntity[]>  {
    const res = await this.get(this.endpoint + `/${resourceType}`);
    return res.data.map(d => new CompanySpecCustomValueSettingEntity(d));
  }
  public async save(resourceType: CompanySpecCustomValueSettingTargetResourceType, data: CompanySpecCustomValueSettingEntity[]) {
    const res = await this.put(this.endpoint + `/${resourceType}`, data);
    return res.data;
  }
}
