


































import {Component, Prop} from 'vue-property-decorator';
import AuthViewBase from "@/views/AuthViewBase";
import MypageBodyHeader from "@/views/common/mypage/components/MypageBodyHeader.vue";
import MyPageBodySection from "@/views/common/mypage/components/MyPageBodySection.vue";
import MyPageBodySettingRow from "@/views/common/mypage/components/MyPageBodySettingRow.vue";
import BooleanSelect from "@/components/Form/BooleanSelect.vue";
import CanEditButton from "@/views/common/mypage/company/components/CanEditButton.vue";
import { ApprovalFlowSettingEntity } from "@/entities/approval-flow-setting-entity";

@Component({
  components: {
    CanEditButton,
    BooleanSelect,
    MyPageBodySettingRow, MyPageBodySection,
    MypageBodyHeader,
  }
})
export default class extends AuthViewBase {
  @Prop({ required: true }) private flows!: ApprovalFlowSettingEntity[];

  private async created() {
  }

  private row(scope: { row: ApprovalFlowSettingEntity }): ApprovalFlowSettingEntity {
    return scope.row;
  }
}
