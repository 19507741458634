














































































import {Component, Prop} from 'vue-property-decorator';
import execWithLoading from "@/utils/loading-handler";
import AuthViewBase from "@/views/AuthViewBase";
import MypageBodyHeader from "@/views/common/mypage/components/MypageBodyHeader.vue";
import MyPageBodySection from "@/views/common/mypage/components/MyPageBodySection.vue";
import MyPageBodySettingRow from "@/views/common/mypage/components/MyPageBodySettingRow.vue";
import BooleanSelect from "@/components/Form/BooleanSelect.vue";
import CanEditButton from "@/views/common/mypage/company/components/CanEditButton.vue";
import {ApprovalFlowSettingEntity, ApprovalFlowSettingStepEntity} from "@/entities/approval-flow-setting-entity";
import {
  ApprovalFlowSettingRepository,
  UpdateApprovalFlowSettingRequest
} from "@/repositories/company/approval-flow-setting-repository";
import {requiredOnBlurRule, requiredRule} from "@/utils/validation-rules";
import { help } from '@/lang/help/setting';
import {CompanyRepository} from "@/repositories/company-repository";
import CompanyEntity from "@/entities/company-entity";
import {ValidateWithScroll} from "@/utils/validation-handler";
import {HandledApiError} from "@/bootstraps/error-handler";

@Component({
  components: {
    CanEditButton,
    BooleanSelect,
    MyPageBodySettingRow, MyPageBodySection,
    MypageBodyHeader,
  }
})
export default class extends AuthViewBase {
  @Prop({required: false}) approvalFlowSettingId?:number;

  private initialized = false;
  private company:CompanyEntity = null as any;
  private flow:ApprovalFlowSettingEntity | null = null;
  private model: UpdateApprovalFlowSettingRequest = {
    name: '',
    departmentIds: [],
  };


  private help = help;
  private rules = {
    name: [ requiredOnBlurRule ]
  };

  protected get isCreateMode(): boolean {
    return this.$route.name === 'mypage.company.approval.flow.create.acceptance' ||
      this.$route.name === 'mypage.company.approval.flow.create.owned';
  }
  protected get forAcceptance(): boolean {
    if(this.$route.name === 'mypage.company.approval.flow.create.acceptance') return true;
    if (this.$route.name === 'mypage.company.approval.flow.create.owned') return false;
    return !!this.flow && this.flow.forAcceptance;
  }
  protected get typeLabel(): string {
    return this.forAcceptance ? this.$t('回収') : this.$t('自社規格書の正規版保存時');
  }

  private async created() {
    await this.load();
  }
  private async load() {
    this.initialized = false;
    await this.$nextTick();
    await execWithLoading(async () => {
      return Promise.all([
        (new CompanyRepository).find(this.companyId).then(res=> {
          this.company = res;
        }),
      ]);
    });

    if (this.approvalFlowSettingId) {
      const flow = this.company.approvalFlowSettings.find(f => f.id === Number(this.approvalFlowSettingId));
      if (!flow) {
        throw new HandledApiError('自社内に存在しない承認ワークフローIDが指定されています。');
      }
      this.flow = flow;
      this.model = {
        name: flow.name,
        departmentIds: flow.departments.map(d => d.id),
      };
    }

    this.initialized = true;
  }

  public async save() {
    if (!(await ValidateWithScroll(this.$refs.form as any))) return;

    await execWithLoading(async () => {
      if (this.isCreateMode) {
        const flowId = await (new ApprovalFlowSettingRepository(this.companyId)).createFlow(this.forAcceptance, this.model);
        await this.$router.push({name: 'mypage.company.approval.flow.edit', params: { approvalFlowSettingId: flowId as any }});
      } else {
        await (new ApprovalFlowSettingRepository(this.companyId)).updateFlow(this.approvalFlowSettingId!, this.model);
      }
      await this.load();
      this.$message( {type: 'success', message: this.$t('保存しました（開始済みの承認ワークフローには影響しません）') });
    });
  }

  private removeStep(row: ApprovalFlowSettingStepEntity) {
    const msg = this.$t('xを削除しますか？', [ row.name ]) + this.$t('（開始済みのワークフローには影響しません）');
    this.$confirm(msg, this.$t('確認'), {
      customClass: 'danger',
      confirmButtonText: this.$t('はい'),
      confirmButtonClass: 'c-button danger no-focus',
      cancelButtonText: this.$t('キャンセル'),
      cancelButtonClass: 'c-button danger-inverse no-focus',
    }).then(async () => {
      await execWithLoading(async () => {
        await (new ApprovalFlowSettingRepository(this.companyId)).deleteStep(this.approvalFlowSettingId!, row.id);
        await this.load();
      });
      this.$message( {type: 'info', message: this.$t('削除しました') });
    }).catch(err => { if (err !== 'cancel') throw err; });
  }

  private row(scope: { row: ApprovalFlowSettingStepEntity }): ApprovalFlowSettingStepEntity {
    return scope.row;
  }
}
