


















import {Component, Prop} from 'vue-property-decorator';
import {ValidateWithScroll} from "@/utils/validation-handler";
import Vue from "vue";

@Component({
  components: {
  }
})
export default class extends Vue {
  @Prop({required: false, default: () => {} }) private rules!: object;
  @Prop({required: true}) private value!: boolean;
  @Prop({required: true}) private title!: string;
  @Prop({required: true}) private description!: string;
  @Prop({required: true}) private buttonLabel!: string;
  @Prop({required: false}) private original!: { id: number, name: string };

  private get visible() { return this.value; }
  private set visible(val) { this.$emit('input', val) ; }

  private data = {
    id: null as number | null,
    name: '',
  };

  private created() {
    if (this.original) {
      this.data.id = this.original.id;
      this.data.name = this.original.name;
    }
  }

  private async save() {
    if (!(await ValidateWithScroll(this.$refs.form as any))) return;

    this.$emit('save', this.data);
  }
}
