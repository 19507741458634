


























import {Component} from 'vue-property-decorator';
import execWithLoading from "@/utils/loading-handler";
import AuthViewBase from "@/views/AuthViewBase";
import {CompanyClientEntity} from "@/entities/company-client-entity";
import { CompanyClientRepository } from '@/repositories/company/company-client-repository';
import {PartnerType, PartnerTypeDict} from "@/entities/specs/partner-entity";
import PartnerIndexSection from "@/views/common/mypage/company/Partner/components/PartnerIndexSection.vue";
import SpecSelfSubmitterRepository from "@/repositories/spec/company/spec-self-submitter-repository";
import SpecSelfSubmitterEntity from "@/entities/specs/spec-self-submitter-entity";
import MypageBodyHeader from "@/views/common/mypage/components/MypageBodyHeader.vue";

@Component({
  components: {
    MypageBodyHeader,
    PartnerIndexSection
  }
})
export default class extends AuthViewBase {
  private readonly PartnerTypeDict = PartnerTypeDict;

  private clients:CompanyClientEntity[]|null = null;
  private selfSubmitters: SpecSelfSubmitterEntity[]|null = null;

  private async created() {
    await execWithLoading(async () => {
      return Promise.all([
        (new CompanyClientRepository(this.companyId)).all().then(list => {
          this.clients = list;
        }),
        (new SpecSelfSubmitterRepository(this.companyId)).findAll().then(list => {
          this.selfSubmitters = list;
        })
      ]);
    });
  }

  private filterClients(type: PartnerType): CompanyClientEntity[] {
    if (!this.clients) return [];
    return this.clients.filter(p => p.type === type);
  }
}
