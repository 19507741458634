






















import {Component} from 'vue-property-decorator';
import AuthViewBase from "@/views/AuthViewBase";
import MemberDepartmentSelect from "@/views/components/Department/MemberDepartmentSelect.vue";
import MypageBodyHeader from "@/views/common/mypage/components/MypageBodyHeader.vue";
import SortableTable from "@/components/Table/SortableTable/SortableTable.vue";
import {
  CompanySpecCustomValueSettingsPerCategory,
  SpecCustomValueCategory, SpecCustomValueCategoryDict,
  splitPerCategory,
} from "@/entities/specs/spec-custom-value-entity";
import CustomValueSettingSection from "@/views/common/mypage/company/components/CustomValueSettingSection.vue";
import CompanySpecCustomValueSettingEntity, {
  CompanySpecCustomValueSettingTargetResourceType,
  ValidatorRules
} from "@/entities/company-spec-custom-value-setting-entity";
import CanEditButton from "@/views/common/mypage/company/components/CanEditButton.vue";
import {ValidateWithScroll} from "@/utils/validation-handler";
import execWithLoading from "@/utils/loading-handler";
import {CompanySpecCustomValueSettingRepository} from "@/repositories/company/company-spec-custom-value-setting-repository";
import {ICustomValueSetting, serializeCustomValueSetting} from "@/entities/interfaces/i-custom-value-setting";
import PartnerIntraCustomValueSettingEntity from "@/entities/partner-intra-custom-value-setting-entity";

@Component({
  components: {
    CanEditButton,
    CustomValueSettingSection,
    SortableTable,
    MypageBodyHeader,
    MemberDepartmentSelect
  }
})
export default class extends AuthViewBase {
  private SpecCustomValueCategory = SpecCustomValueCategory;
  private CompanySpecCustomValueTargetResourceType = CompanySpecCustomValueSettingTargetResourceType;
  private ValidatorRules = ValidatorRules;

  private model: CompanySpecCustomValueSettingsPerCategory = {} as any;
  private initialized:boolean = false;

  private async created() {
    await this.load();
  }
  private async load() {
    await execWithLoading(async () => {
      this.initialized = false;
      await Promise.all([
        (new CompanySpecCustomValueSettingRepository(this.companyId))
          .index(CompanySpecCustomValueSettingTargetResourceType.SelfSubmission)
          .then(list => {
            this.model = splitPerCategory(list);
          })
      ]);
      this.initialized = true;
    });
  }

  private getTitle(category): string {
    return SpecCustomValueCategoryDict.find(c => c.id === category)!.label;
  }

  private insert(category: SpecCustomValueCategory, ev: { data:ICustomValueSetting[], index:number }) {
    const newOne = new CompanySpecCustomValueSettingEntity({
      inputType: null as any,
      targetResourceType: CompanySpecCustomValueSettingTargetResourceType.SelfSubmission,
      category: category
    });
    ev.data.splice(ev.index, 0, newOne);
  }

  private async assertNoError() {
    let noError = true;
    for(const form of this.$refs.form as any[]) {
      if (!(await ValidateWithScroll(form))) {
        noError = false;
      }
    }
    return noError;
  }
  private async save() {
    if (!(await this.assertNoError())) return;

    execWithLoading(async () => {
      const data = this.serialize();
      await (new CompanySpecCustomValueSettingRepository(this.companyId))
        .save(CompanySpecCustomValueSettingTargetResourceType.SelfSubmission, data);
    }).then(() => {
      this.$message({type: 'success', message: this.$t('保存しました') });
      this.load();
    });
  }

  private serialize(): CompanySpecCustomValueSettingEntity[] {
    const data = Object.values(this.model).flatMap(list => list) as CompanySpecCustomValueSettingEntity[];
    return serializeCustomValueSetting(data) as CompanySpecCustomValueSettingEntity[];
  }
}
