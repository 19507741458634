


























import { Vue, Component, Prop} from 'vue-property-decorator';
import LoadingHandler from '@/utils/loading-handler';

import UserEntity, {ValidatorRules} from '@/entities/user-entity';
import UserRepository from '@/repositories/user-repository';
import pick from 'lodash/pick';

import {ValidateWithScroll} from "@/utils/validation-handler";
import MypageBodyHeader from "@/views/common/mypage/components/MypageBodyHeader.vue";

@Component({
  components: {MypageBodyHeader}
})
export default class extends Vue {
  private user:UserEntity|null = null;

  private get rules(){
    return {
      name: ValidatorRules.name,
      nameKana: ValidatorRules.nameKana,
      birthday: ValidatorRules.birthday,
    };
  }

  private created() {
    LoadingHandler(async () => {
      this.user = await (new UserRepository).findById(this.$auth.user.id);
    });
  }

  private async submit() {
    if (!(await ValidateWithScroll(this.$refs.form as any))) return;

    LoadingHandler(() => {
      return (new UserRepository).update(this.$auth.user.id, this.user!);
    }).then(() => {
      this.$message({type: 'success', message: this.$t('プロフィールを更新しました。画面更新のためリロードします')});
      setTimeout(() => location.reload(), 1000); // Authを更新するためにとりあえずReload
    });

  }

}
