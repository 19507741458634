import {read} from "fs";

export interface IUploadFileRule {
  maxFileSize: number;
  availableFileTypeMimeList?: string[];
}
export enum ReadAsType {
  ArrayBuffer = 'ArrayBuffer',
  DataURL= 'DataUrl',
  BinaryString = 'BinaryString',
  Text = 'Text',
}

export default class OnFileSelectedHandler {
  private uploadFileRule!:IUploadFileRule;

  constructor(rule: IUploadFileRule, private readAsType:ReadAsType = ReadAsType.DataURL) {
    this.uploadFileRule = rule;
  }

  private validateFile(file:File){
    if (!!this.uploadFileRule.availableFileTypeMimeList && this.uploadFileRule.availableFileTypeMimeList.indexOf(file.type) < 0) {
      return [false, "このファイル形式には対応していません"];
    }
    if (file.size > this.uploadFileRule.maxFileSize) {
      const maxFileSizeMega = this.uploadFileRule.maxFileSize / 1024 / 1024;
      return [false, `ファイルサイズは最大${maxFileSizeMega}Mです`];
    }

    return [true, ''];
  }

  public onFileSelected(files:any): Promise<any> {
    if (!files || !files.length) {
      return Promise.reject();
    }
    const file = files[0];
    return this.validateAndRead(file);
  }

  public validateAndRead(file:File): Promise<any> {
    return new Promise((resolve, reject) => {
      const [validateResult, errorMsg] = this.validateFile(file);
      if (!validateResult) {
        return reject(errorMsg);
      }

      const reader = new FileReader();
      reader.onload = ((response: any) => {
        resolve(response);
      });

      switch (this.readAsType) {
        case ReadAsType.ArrayBuffer:
          reader.readAsArrayBuffer(file);
          break;
        case ReadAsType.DataURL:
          reader.readAsDataURL(file);
          break;
        case ReadAsType.Text:
          reader.readAsText(file);
          break;
        case ReadAsType.BinaryString:
          reader.readAsBinaryString(file);
          break;
      }
    });
  }
}
