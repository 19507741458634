export const Currencies = [
  {name: 'UAEディルハム', code: 'AED', label: 'UAEディルハム（AED）'},
  {name: 'アフガニ', code: 'AFN', label: 'アフガニ（AFN）'},
  {name: 'レク', code: 'ALL', label: 'レク（ALL）'},
  {name: 'ドラム', code: 'AMD', label: 'ドラム（AMD）'},
  {name: 'アンティル・ギルダー', code: 'ANG', label: 'アンティル・ギルダー（ANG）'},
  {name: 'クワンザ', code: 'AOA', label: 'クワンザ（AOA）'},
  {name: 'アルゼンチン・ペソ', code: 'ARS', label: 'アルゼンチン・ペソ（ARS）'},
  {name: 'オーストラリア・ドル', code: 'AUD', label: 'オーストラリア・ドル（AUD）'},
  {name: 'アルバ・フロリン', code: 'AWG', label: 'アルバ・フロリン（AWG）'},
  {name: 'アゼルバイジャン・マナト', code: 'AZN', label: 'アゼルバイジャン・マナト（AZN）'},
  {name: 'バルバドス・ドル', code: 'BBD', label: 'バルバドス・ドル（BBD）'},
  {name: 'タカ', code: 'BDT', label: 'タカ（BDT）'},
  {name: 'レフ', code: 'BGN', label: 'レフ（BGN）'},
  {name: 'バーレーン・ディナール', code: 'BHD', label: 'バーレーン・ディナール（BHD）'},
  {name: 'ブルンジ・フラン', code: 'BIF', label: 'ブルンジ・フラン（BIF）'},
  {name: 'バミューダ・ドル', code: 'BMD', label: 'バミューダ・ドル（BMD）'},
  {name: 'ブルネイ・ドル', code: 'BND', label: 'ブルネイ・ドル（BND）'},
  {name: 'ボリビアーノ', code: 'BOB', label: 'ボリビアーノ（BOB）'},
  {name: 'レアル', code: 'BRL', label: 'レアル（BRL）'},
  {name: 'バハマ・ドル', code: 'BSD', label: 'バハマ・ドル（BSD）'},
  {name: 'ニュルタム', code: 'BTN', label: 'ニュルタム（BTN）'},
  {name: 'プラ', code: 'BWP', label: 'プラ（BWP）'},
  {name: 'ベラルーシ・ルーブル', code: 'BYN', label: 'ベラルーシ・ルーブル（BYN）'},
  {name: 'ベリーズ・ドル', code: 'BZD', label: 'ベリーズ・ドル（BZD）'},
  {name: 'カナダ・ドル', code: 'CAD', label: 'カナダ・ドル（CAD）'},
  {name: 'コンゴ・フラン', code: 'CDF', label: 'コンゴ・フラン（CDF）'},
  {name: 'スイス・フラン', code: 'CHF', label: 'スイス・フラン（CHF）'},
  {name: 'チリ・ペソ', code: 'CLP', label: 'チリ・ペソ（CLP）'},
  {name: '人民元', code: 'CNY', label: '人民元（CNY）'},
  {name: 'コロンビア・ペソ', code: 'COP', label: 'コロンビア・ペソ（COP）'},
  {name: 'コスタリカ・コロン', code: 'CRC', label: 'コスタリカ・コロン（CRC）'},
  {name: 'キューバ・ペソ', code: 'CUP', label: 'キューバ・ペソ（CUP）'},
  {name: 'カーボベルデ・エスクード', code: 'CVE', label: 'カーボベルデ・エスクード（CVE）'},
  {name: 'チェコ・コルナ', code: 'CZK', label: 'チェコ・コルナ（CZK）'},
  {name: 'ジブチ・フラン', code: 'DJF', label: 'ジブチ・フラン（DJF）'},
  {name: 'デンマーク・クローネ', code: 'DKK', label: 'デンマーク・クローネ（DKK）'},
  {name: 'ドミニカ・ペソ', code: 'DOP', label: 'ドミニカ・ペソ（DOP）'},
  {name: 'アルジェリア・ディナール', code: 'DZD', label: 'アルジェリア・ディナール（DZD）'},
  {name: 'エジプト・ポンド', code: 'EGP', label: 'エジプト・ポンド（EGP）'},
  {name: 'ナクファ', code: 'ERN', label: 'ナクファ（ERN）'},
  {name: 'ブル', code: 'ETB', label: 'ブル（ETB）'},
  {name: 'ユーロ', code: 'EUR', label: 'ユーロ（EUR）'},
  {name: 'フィジー・ドル', code: 'FJD', label: 'フィジー・ドル（FJD）'},
  {name: 'フォークランド諸島ポンド', code: 'FKP', label: 'フォークランド諸島ポンド（FKP）'},
  {name: 'スターリング・ポンド', code: 'GBP', label: 'スターリング・ポンド（GBP）'},
  {name: 'ラリ', code: 'GEL', label: 'ラリ（GEL）'},
  {name: 'セディ', code: 'GHS', label: 'セディ（GHS）'},
  {name: 'ジブラルタル・ポンド', code: 'GIP', label: 'ジブラルタル・ポンド（GIP）'},
  {name: 'ダラシ', code: 'GMD', label: 'ダラシ（GMD）'},
  {name: 'ギニア・フラン', code: 'GNF', label: 'ギニア・フラン（GNF）'},
  {name: 'ケツァル', code: 'GTQ', label: 'ケツァル（GTQ）'},
  {name: 'ガイアナ・ドル', code: 'GYD', label: 'ガイアナ・ドル（GYD）'},
  {name: '香港ドル', code: 'HKD', label: '香港ドル（HKD）'},
  {name: 'レンピラ', code: 'HNL', label: 'レンピラ（HNL）'},
  {name: 'クーナ', code: 'HRK', label: 'クーナ（HRK）'},
  {name: 'グールド', code: 'HTG', label: 'グールド（HTG）'},
  {name: 'フォリント', code: 'HUF', label: 'フォリント（HUF）'},
  {name: 'ルピア', code: 'IDR', label: 'ルピア（IDR）'},
  {name: '新シェケル', code: 'ILS', label: '新シェケル（ILS）'},
  {name: 'インド・ルピー', code: 'INR', label: 'インド・ルピー（INR）'},
  {name: 'イラク・ディナール', code: 'IQD', label: 'イラク・ディナール（IQD）'},
  {name: 'イラン・リヤル', code: 'IRR', label: 'イラン・リヤル（IRR）'},
  {name: 'アイスランド・クローナ', code: 'ISK', label: 'アイスランド・クローナ（ISK）'},
  {name: 'ジャマイカ・ドル', code: 'JMD', label: 'ジャマイカ・ドル（JMD）'},
  {name: 'ヨルダン・ディナール', code: 'JOD', label: 'ヨルダン・ディナール（JOD）'},
  {name: '円', code: 'JPY', label: '円（JPY）'},
  {name: 'ケニア・シリング', code: 'KES', label: 'ケニア・シリング（KES）'},
  {name: 'キルギス・ソム', code: 'KGS', label: 'キルギス・ソム（KGS）'},
  {name: 'リエル', code: 'KHR', label: 'リエル（KHR）'},
  {name: 'コモロ・フラン', code: 'KMF', label: 'コモロ・フラン（KMF）'},
  {name: '朝鮮民主主義人民共和国ウォン', code: 'KPW', label: '朝鮮民主主義人民共和国ウォン（KPW）'},
  {name: '大韓民国ウォン', code: 'KRW', label: '大韓民国ウォン（KRW）'},
  {name: 'クウェート・ディナール', code: 'KWD', label: 'クウェート・ディナール（KWD）'},
  {name: 'ケイマン諸島・ドル', code: 'KYD', label: 'ケイマン諸島・ドル（KYD）'},
  {name: 'テンゲ', code: 'KZT', label: 'テンゲ（KZT）'},
  {name: 'キープ', code: 'LAK', label: 'キープ（LAK）'},
  {name: 'レバノン・ポンド', code: 'LBP', label: 'レバノン・ポンド（LBP）'},
  {name: 'スリランカ・ルピー', code: 'LKR', label: 'スリランカ・ルピー（LKR）'},
  {name: 'リベリア・ドル', code: 'LRD', label: 'リベリア・ドル（LRD）'},
  {name: 'ロチ', code: 'LSL', label: 'ロチ（LSL）'},
  {name: 'リビア・ディナール', code: 'LYD', label: 'リビア・ディナール（LYD）'},
  {name: 'モロッコ・ディルハム', code: 'MAD', label: 'モロッコ・ディルハム（MAD）'},
  {name: 'モルドバ・レウ', code: 'MDL', label: 'モルドバ・レウ（MDL）'},
  {name: 'マダガスカル・アリアリ', code: 'MGA', label: 'マダガスカル・アリアリ（MGA）'},
  {name: 'マケドニア・デナール', code: 'MKD', label: 'マケドニア・デナール（MKD）'},
  {name: 'チャット', code: 'MMK', label: 'チャット（MMK）'},
  {name: 'トゥグルグ', code: 'MNT', label: 'トゥグルグ（MNT）'},
  {name: 'マカオ・パタカ', code: 'MOP', label: 'マカオ・パタカ（MOP）'},
  {name: 'ウギア', code: 'MRU[10]', label: 'ウギア（MRU[10]）'},
  {name: 'モーリシャス・ルピー', code: 'MUR', label: 'モーリシャス・ルピー（MUR）'},
  {name: 'ルフィヤ', code: 'MVR', label: 'ルフィヤ（MVR）'},
  {name: 'マラウイ・クワチャ', code: 'MWK', label: 'マラウイ・クワチャ（MWK）'},
  {name: 'メキシコ・ペソ', code: 'MXN', label: 'メキシコ・ペソ（MXN）'},
  {name: 'リンギット', code: 'MYR', label: 'リンギット（MYR）'},
  {name: 'メティカル', code: 'MZN', label: 'メティカル（MZN）'},
  {name: 'ナミビア・ドル', code: 'NAD', label: 'ナミビア・ドル（NAD）'},
  {name: 'ナイラ', code: 'NGN', label: 'ナイラ（NGN）'},
  {name: 'ニカラグア・コルドバ', code: 'NIO', label: 'ニカラグア・コルドバ（NIO）'},
  {name: 'ノルウェー・クローネ', code: 'NOK', label: 'ノルウェー・クローネ（NOK）'},
  {name: 'ネパール・ルピー', code: 'NPR', label: 'ネパール・ルピー（NPR）'},
  {name: 'ニュージーランド・ドル', code: 'NZD', label: 'ニュージーランド・ドル（NZD）'},
  {name: 'オマーン・リアル', code: 'OMR', label: 'オマーン・リアル（OMR）'},
  {name: 'バルボア', code: 'PAB', label: 'バルボア（PAB）'},
  {name: 'ヌエボ・ソル', code: 'PEN', label: 'ヌエボ・ソル（PEN）'},
  {name: 'キナ', code: 'PGK', label: 'キナ（PGK）'},
  {name: 'フィリピン・ペソ', code: 'PHP', label: 'フィリピン・ペソ（PHP）'},
  {name: 'パキスタン・ルピー', code: 'PKR', label: 'パキスタン・ルピー（PKR）'},
  {name: 'ズウォティ', code: 'PLN', label: 'ズウォティ（PLN）'},
  {name: 'グアラニー', code: 'PYG', label: 'グアラニー（PYG）'},
  {name: 'カタール・リヤル', code: 'QAR', label: 'カタール・リヤル（QAR）'},
  {name: 'ルーマニア・レウ', code: 'RON', label: 'ルーマニア・レウ（RON）'},
  {name: 'セルビア・ディナール', code: 'RSD', label: 'セルビア・ディナール（RSD）'},
  {name: 'ロシア・ルーブル', code: 'RUB', label: 'ロシア・ルーブル（RUB）'},
  {name: 'ルワンダ・フラン', code: 'RWF', label: 'ルワンダ・フラン（RWF）'},
  {name: 'サウジアラビア・リヤル', code: 'SAR', label: 'サウジアラビア・リヤル（SAR）'},
  {name: 'ソロモン諸島ドル', code: 'SBD', label: 'ソロモン諸島ドル（SBD）'},
  {name: 'セーシェル・ルピー', code: 'SCR', label: 'セーシェル・ルピー（SCR）'},
  {name: 'スーダン・ポンド', code: 'SDG', label: 'スーダン・ポンド（SDG）'},
  {name: 'スウェーデン・クローナ', code: 'SEK', label: 'スウェーデン・クローナ（SEK）'},
  {name: 'シンガポール・ドル', code: 'SGD', label: 'シンガポール・ドル（SGD）'},
  {name: 'セントヘレナ・ポンド', code: 'SHP', label: 'セントヘレナ・ポンド（SHP）'},
  {name: 'レオン', code: 'SLL', label: 'レオン（SLL）'},
  {name: 'ソマリア・シリング', code: 'SOS', label: 'ソマリア・シリング（SOS）'},
  {name: 'スリナム・ドル', code: 'SRD', label: 'スリナム・ドル（SRD）'},
  {name: '南スーダン・ポンド', code: 'SSP', label: '南スーダン・ポンド（SSP）'},
  {name: 'ドブラ', code: 'STN[12]', label: 'ドブラ（STN[12]）'},
  {name: 'サルバドール・コロン', code: 'SVC', label: 'サルバドール・コロン（SVC）'},
  {name: 'シリア・ポンド', code: 'SYP', label: 'シリア・ポンド（SYP）'},
  {name: 'リランゲニ', code: 'SZL', label: 'リランゲニ（SZL）'},
  {name: 'バーツ', code: 'THB', label: 'バーツ（THB）'},
  {name: 'ソモニ', code: 'TJS', label: 'ソモニ（TJS）'},
  {name: 'トルクメニスタン・マナト', code: 'TMT', label: 'トルクメニスタン・マナト（TMT）'},
  {name: 'チュニジア・ディナール', code: 'TND', label: 'チュニジア・ディナール（TND）'},
  {name: 'パアンガ', code: 'TOP', label: 'パアンガ（TOP）'},
  {name: 'トルコリラ', code: 'TRY', label: 'トルコリラ（TRY）'},
  {name: 'トリニダード・トバゴ・ドル', code: 'TTD', label: 'トリニダード・トバゴ・ドル（TTD）'},
  {name: 'ニュー台湾ドル', code: 'TWD', label: 'ニュー台湾ドル（TWD）'},
  {name: 'タンザニア・シリング', code: 'TZS', label: 'タンザニア・シリング（TZS）'},
  {name: 'フリヴニャ', code: 'UAH', label: 'フリヴニャ（UAH）'},
  {name: 'ウガンダ・シリング', code: 'UGX', label: 'ウガンダ・シリング（UGX）'},
  {name: 'アメリカ合衆国ドル', code: 'USD', label: 'アメリカ合衆国ドル（USD）'},
  {name: 'ウルグアイ・ペソ', code: 'UYU', label: 'ウルグアイ・ペソ（UYU）'},
  {name: 'スム', code: 'UZS', label: 'スム（UZS）'},
  {name: 'ドン', code: 'VND', label: 'ドン（VND）'},
  {name: 'バツ', code: 'VUV', label: 'バツ（VUV）'},
  {name: 'タラ', code: 'WST', label: 'タラ（WST）'},
  {name: '中央アフリカCFAフラン', code: 'XAF', label: '中央アフリカCFAフラン（XAF）'},
  {name: '東カリブ・ドル', code: 'XCD', label: '東カリブ・ドル（XCD）'},
  {name: '西アフリカCFAフラン', code: 'XOF', label: '西アフリカCFAフラン（XOF）'},
  {name: 'CFPフラン（太平洋フラン）', code: 'XPF', label: 'CFPフラン（太平洋フラン）（XPF）'},
  {name: 'イエメン・リアル', code: 'YER', label: 'イエメン・リアル（YER）'},
  {name: 'ランド', code: 'ZAR', label: 'ランド（ZAR）'},
  {name: 'ザンビア・クワチャ', code: 'ZMW', label: 'ザンビア・クワチャ（ZMW）'},
  {name: 'ジンバブエ・ドル', code: 'ZWL', label: 'ジンバブエ・ドル（ZWL）'},
];
