




















import { Component, Prop} from 'vue-property-decorator';
import LoadingHandler from '@/utils/loading-handler';

import {ValidatorRules} from '@/entities/user-entity';
import Qs from 'qs';
import AuthRepository from "@/repositories/auth-repository";
import {ValidateWithScroll} from "@/utils/validation-handler";
import UserRepository from "@/repositories/user-repository";
import AuthViewBase from "@/views/AuthViewBase";
import MypageBodyHeader from "@/views/common/mypage/components/MypageBodyHeader.vue";
import {dispatchInitialized} from "@/libs/cypress";

@Component({
  components: {MypageBodyHeader}
})
export default class extends AuthViewBase {
  private data = {
    email: "",
  };

  private error:string = "";

  private get rules(){
    return {
      email: ValidatorRules.email,
    };
  }

  private created() {
    this.handleRedirected();

    (new UserRepository).findById(this.$auth.user.id).then(user => {
      this.data.email = user.email;
      dispatchInitialized();
    });
  }

  private handleRedirected() {
    const params = Qs.parse(location.search.substring(1));
    if (params.status) {
      if (params.status === 'success') {
        this.$message({type: 'success', message: this.$t('メールアドレスの変更が完了しました')});
      } else {
        const mes:string = (params.message || this.$t('メールアドレスの変更に失敗しました。お手数をおかけ致しますが、最初からやり直してください') ) as string;
        this.$message({type: 'error', message: mes } );
      }
    }
    params.status = undefined;
    history.replaceState(null, null as any, location.origin + location.pathname + '?' + Qs.stringify(params));
  }

  private async submit() {
    if (!(await ValidateWithScroll(this.$refs.form as any))) return;

    LoadingHandler(() => {
      return (new AuthRepository).sendVerifyChangingEmail(this.data.email);
    }, 8000).then(async () => {
      this.data.email = '';
      this.$message({type: 'success', message: this.$t('確認メールを送信しました。メール内のリンクをクリック後に変更が適用されます')});
    });
  }

}
