import RepositoryBase, {IFindOption} from "@/repositories/repository-base";
import {PartnerEntity} from "@/entities/specs/partner-entity";

// TODO: ファイルをspecの上に移動する
export default class PartnerRepository extends RepositoryBase<PartnerEntity> {
  protected ctor:new(data) => PartnerEntity = PartnerEntity;

  public constructor(companyId) {
    super();
    this.endpoint = `companies/${companyId}/partners`
  }

  public find(id:number) {
    return this.findById(id);
  }
  public all(opt?:IFindOption) {
    return this.findAll(opt);
  }
  public update(id, data:Partial<PartnerEntity>) {
    return super.update(id, data);
  }
  public destroy(id) {
    return super.destroy(id);
  }
}

