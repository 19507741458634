





















import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class SortableDropdown<T> extends Vue {
  @Prop({required: true}) private dataList!: T[];
  @Prop({required: true}) private value!: T;
  @Prop({default: false}) private showEdit!: boolean;
  @Prop({default: false}) private showInsert!: boolean;

  private visible = false;
}
