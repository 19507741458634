



































































import { Component, Prop} from 'vue-property-decorator';
import execWithLoading from "@/utils/loading-handler";
import AuthViewBase from "@/views/AuthViewBase";
import PartnerForm from "@/views/spec/companies/components/spec-components/create/components/PartnerForm.vue";
import {CompanyClientEntity, ValidatorRules} from '@/entities/company-client-entity';
import {PartnerTypeDict} from "@/entities/specs/partner-entity";

import {ValidateWithScroll} from "@/utils/validation-handler";
import {CompanyClientRepository} from "@/repositories/company/company-client-repository";
import {HideableFieldsDict} from "@/entities/specs/spec-ingredient-entity";
import CanEditButton from "@/views/common/mypage/company/components/CanEditButton.vue";
import MypageBodyHeader from "@/views/common/mypage/components/MypageBodyHeader.vue";
@Component({
  components: {
    MypageBodyHeader,
    PartnerForm,
    CanEditButton
  }
})
export default class extends AuthViewBase {
  @Prop({required: true}) private clientId!:number;
  private readonly rules = ValidatorRules;
  private readonly HideableFieldsOnSubmit = HideableFieldsDict;

  private client:CompanyClientEntity|null = null;
  private readonly PartnerTypeDict = PartnerTypeDict;

  private async created() {
    await execWithLoading(async () => {
      this.client = await (new CompanyClientRepository(this.companyId)).findCompanyClient(this.clientId!);
    });
  }

  private async save() {
    if (!(await ValidateWithScroll(this.$refs.form as any))) return;

    execWithLoading(async () => {
      await (new CompanyClientRepository(this.companyId)).updateCompanyClient(this.clientId!, this.client!);
    }).then(() => {
      this.$router.push({name: 'mypage.company.client'}).then(() => {
        this.$message({type: 'success', message: this.$t('保存しました')});
      });
    });
  }
}
