















































import { Component, Prop} from 'vue-property-decorator';
import LoadingHandler from '@/utils/loading-handler';

import VueScrollTo from 'vue-scrollto';
import {ValidateWithScroll} from "@/utils/validation-handler";
import CompanyEntity, {ValidatorRules} from "@/entities/company-entity";
import {CompanyRepository} from "@/repositories/company-repository";
import AuthViewBase from "@/views/AuthViewBase";
import PartnerFormAddress from "@/components/Project/PartnerFormAddress.vue";
import MypageBodyHeader from "@/views/common/mypage/components/MypageBodyHeader.vue";

@Component({
  components: {
    MypageBodyHeader,
    PartnerFormAddress,
  }
})
export default class extends AuthViewBase {
  private company:CompanyEntity|null = null;

  private get rules(){
    return ValidatorRules;
  }

  private async created() {
    this.company = await (new CompanyRepository()).findById(this.companyId);
  }

  private async submit() {
    if (!(await ValidateWithScroll(this.$refs.form as any))) return;

    LoadingHandler(() => {
      return (new CompanyRepository).update(this.company!.id, this.company!);
    }).then(() => {
      const comp = this.$message({type: 'success', message: this.$t('プロフィールを更新しました')});
      VueScrollTo.scrollTo(comp);
    })
  }

}
