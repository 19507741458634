







import { Component, Vue, Prop } from 'vue-property-decorator';

import PayJP from "@/libs/payjp";
import { DIContainer } from '@/utils/di-container';

@Component
export default class extends Vue {
  @Prop({default: 'default'})
  private size!: 'small' | 'default';

  public payjpElement = {} as any;
  private error:string = '';

  private mounted() {
    // https://pay.jp/docs/payjs#css-property
    const fontSize = this.size === 'default' ? '16px' : '14px';
    var style = {
      base: {
        fontSize: fontSize,
        lineHeight: 1,
        '::placeholder': {
          color: '#BBBBBB',
        }
      },
      invalid: {
      }
    }

    this.payjpElement = (DIContainer.payjp as PayJP).createCard(style);
    this.payjpElement.mount('#credit-card-form')

    this.payjpElement.on('change', (event) => {
      /*
      {
        brand: 'Visa', complete: false, elementType: 'card', empty: false,
        error: { message: '入力が完了していません。', type: 'validation_error', code: 'incomplete_error' }
      }
      */
      this.error = event.error ? event.error.message : '';
      this.$emit('change', event.complete as boolean);
    })
  }
}
