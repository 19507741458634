import RepositoryBase, {IFindOption} from "@/repositories/repository-base";
import {CompanyIntraCategoryProductEntity} from "@/entities/company-intra-category-product-entity";

export class CompanyIntraCategoryProductRepository extends RepositoryBase<CompanyIntraCategoryProductEntity> {
  protected ctor:new(data) => CompanyIntraCategoryProductEntity = CompanyIntraCategoryProductEntity;

  protected companyId!:number;

  public constructor(companyId) {
    super();
    this.companyId = companyId;
    this.endpoint = `companies/${companyId}/intra-categories/products`
  }

  public async all(): Promise<CompanyIntraCategoryProductEntity[]>  {
    return this.findAll();
  }
  public async add(data: CompanyIntraCategoryProductEntity) {
    return this.create(data);
  }
  public async updateName(id:number, name: string): Promise<void>  {
    await super.put(this.endpoint + `/${id}/name`, {name});
  }
  public async updateSortOrder(id:number, sortOrder: number): Promise<void>  {
    await super.put(this.endpoint + `/${id}/sort-order`, {sortOrder});
  }
  public async remove(id: number) {
    return this.destroy(id);
  }
}
