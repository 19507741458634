import {ValidatorRules} from "@/entities/user-entity";

export interface IConfirmPassword {
  password: string;
  passwordConfirm: string;
}

const confirmPassword = (data:IConfirmPassword, callback) => {
  if (data.passwordConfirm === '') return callback();

  if (data.password !== data.passwordConfirm) {
    return callback(new Error('パスワードが一致しません'));
  }
  callback();
};

export default function getPasswordRules(data:IConfirmPassword) {
  return {
    password: ValidatorRules.password.concat(
      [{ validator: (rule, value, callback) => confirmPassword(data, callback), trigger: 'blur' }]
    ),
    passwordConfirm: ValidatorRules.password.concat(
      [{ validator: (rule, value, callback) => confirmPassword(data, callback), trigger: 'blur' }]
    ),
  };
}
