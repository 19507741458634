import {getTextByLang as t} from "@/bootstraps/locale";

export const JapanPrefecturesLang = {
  '北海道': t({
    ja: "北海道",
    en: "Hokkaido",
    cn: "北海道"
  }),
  '青森県': t({
    ja: "青森県",
    en: "Aomori",
    cn: "青森"
  }),
  '岩手県': t({
    ja: "岩手県",
    en: "Iwate",
    cn: "岩手"
  }),
  '宮城県': t({
    ja: "宮城県",
    en: "Miyagi",
    cn: "宫城"
  }),
  '秋田県': t({
    ja: "秋田県",
    en: "Akita",
    cn: "秋田"
  }),
  '山形県': t({
    ja: "山形県",
    en: "Yamagata",
    cn: "山形"
  }),
  '福島県': t({
    ja: "福島県",
    en: "Fukushima",
    cn: "福岛"
  }),
  '茨城県': t({
    ja: "茨城県",
    en: "Ibaraki",
    cn: "茨城"
  }),
  '栃木県': t({
    ja: "栃木県",
    en: "Tochigi",
    cn: "枥木"
  }),
  '群馬県': t({
    ja: "群馬県",
    en: "Gunma",
    cn: "群马"
  }),
  '埼玉県': t({
    ja: "埼玉県",
    en: "Saitama",
    cn: "埼玉"
  }),
  '千葉県': t({
    ja: "千葉県",
    en: "Chiba",
    cn: "千叶"
  }),
  '東京都': t({
    ja: "東京都",
    en: "Tokyo",
    cn: "东京"
  }),
  '神奈川県': t({
    ja: "神奈川県",
    en: "Kanagawa",
    cn: "神奈川"
  }),
  '新潟県': t({
    ja: "新潟県",
    en: "Niigata",
    cn: "新泻"
  }),
  '富山県': t({
    ja: "富山県",
    en: "Toyama",
    cn: "富山"
  }),
  '石川県': t({
    ja: "石川県",
    en: "Ishikawa",
    cn: "石川"
  }),
  '福井県': t({
    ja: "福井県",
    en: "Fukui",
    cn: "福井"
  }),
  '山梨県': t({
    ja: "山梨県",
    en: "Yamanashi",
    cn: "山梨"
  }),
  '長野県': t({
    ja: "長野県",
    en: "Nagano",
    cn: "长野"
  }),
  '岐阜県': t({
    ja: "岐阜県",
    en: "Gifu",
    cn: "岐阜"
  }),
  '静岡県': t({
    ja: "静岡県",
    en: "Shizuoka",
    cn: "静冈"
  }),
  '愛知県': t({
    ja: "愛知県",
    en: "Aichi",
    cn: "爱知"
  }),
  '三重県': t({
    ja: "三重県",
    en: "Mie",
    cn: "三重"
  }),
  '滋賀県': t({
    ja: "滋賀県",
    en: "Shiga",
    cn: "滋贺"
  }),
  '京都府': t({
    ja: "京都府",
    en: "Kyoto",
    cn: "京都市区"
  }),
  '大阪府': t({
    ja: "大阪府",
    en: "Osaka",
    cn: "大坂"
  }),
  '兵庫県': t({
    ja: "兵庫県",
    en: "Hyogo",
    cn: "兵库"
  }),
  '奈良県': t({
    ja: "奈良県",
    en: "Nara",
    cn: "奈良"
  }),
  '和歌山県': t({
    ja: "和歌山県",
    en: "Wakayama",
    cn: "和歌山"
  }),
  '鳥取県': t({
    ja: "鳥取県",
    en: "Tottori",
    cn: "鸟取"
  }),
  '島根県': t({
    ja: "島根県",
    en: "Shimane",
    cn: "岛根"
  }),
  '岡山県': t({
    ja: "岡山県",
    en: "Okayama",
    cn: "冈山"
  }),
  '広島県': t({
    ja: "広島県",
    en: "Hiroshima",
    cn: "广岛"
  }),
  '山口県': t({
    ja: "山口県",
    en: "Yamaguchi",
    cn: "山口"
  }),
  '徳島県': t({
    ja: "徳島県",
    en: "Tokushima",
    cn: "德岛"
  }),
  '香川県': t({
    ja: "香川県",
    en: "Kagawa",
    cn: "香川"
  }),
  '愛媛県': t({
    ja: "愛媛県",
    en: "Ehime",
    cn: "爱媛"
  }),
  '高知県': t({
    ja: "高知県",
    en: "Kouchi",
    cn: "高知"
  }),
  '福岡県': t({
    ja: "福岡県",
    en: "Fukuoka",
    cn: "福冈"
  }),
  '佐賀県': t({
    ja: "佐賀県",
    en: "Saga",
    cn: "佐贺"
  }),
  '長崎県': t({
    ja: "長崎県",
    en: "Nagasaki",
    cn: "长崎"
  }),
  '熊本県': t({
    ja: "熊本県",
    en: "Kumamoto",
    cn: "熊本"
  }),
  '大分県': t({
    ja: "大分県",
    en: "Oita",
    cn: "大分"
  }),
  '宮崎県': t({
    ja: "宮崎県",
    en: "Miyazaki",
    cn: "宫崎"
  }),
  '鹿児島県': t({
    ja: "鹿児島県",
    en: "Kagoshima",
    cn: "鹿儿岛"
  }),
  '沖縄県': t({
    ja: "沖縄県",
    en: "Okinawa",
    cn: "冲绳"
  }),

};
