import {SpecCustomValueCategory } from "@/entities/specs/spec-custom-value-entity";
import {CustomValueSettingEntityBase, ValidatorRules} from "@/entities/interfaces/i-custom-value-setting";

export default class CompanySpecCustomValueSettingEntity extends CustomValueSettingEntityBase {
  public targetResourceType!: CompanySpecCustomValueSettingTargetResourceType;
  public category!: SpecCustomValueCategory;

  constructor(init:Partial<CompanySpecCustomValueSettingEntity> = {}) {
    super(init);
  }
}

export { ValidatorRules } ;

export enum CompanySpecCustomValueSettingTargetResourceType {
  OwnedSpec = 1,
  Acceptance = 2,
  SelfSubmission = 3,
}
