




































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import CompanyEntity from "@/entities/company-entity";
import { AccessibilityToOtherDeptResourcesDict } from '@/entities/company-setting-entity';
import {CompanySettingUpdateRequestBase} from "@/repositories/company-repository";
import {help as PopoverText} from '@/lang/help/setting';

import DepartmentCreateSelect from "@/views/components/Department/DepartmentCreateSelect.vue";
import MyPageBodySection from "@/views/common/mypage/components/MyPageBodySection.vue";
import MyPageBodySettingRow from "@/views/common/mypage/components/MyPageBodySettingRow.vue";

@Component({
  components: {
    MyPageBodySettingRow,
    MyPageBodySection,
    DepartmentCreateSelect: DepartmentCreateSelect
  }
})
export default class SettingCommonSection extends Vue {
  @Prop({required: true}) private company!:CompanyEntity;
  @Prop({required: true}) private value!: CompanySettingUpdateRequestBase;

  private readonly Popover = PopoverText;
  private readonly AccessibilityToOtherDeptResourcesDict = AccessibilityToOtherDeptResourcesDict;

  @Watch('value.isDepartmentEnabled')
  private onIsDepartmentEnabledUpdated(newVal) {
    if (!newVal) return;

    const willSomeUserMadeAsManagementDepartment =
      this.value.isDepartmentEnabled &&
      !this.company!.setting.isDepartmentEnabled &&
      this.company!.members.some(m => m.departments.length === 0);

    if (willSomeUserMadeAsManagementDepartment) {
      this.$alert(
        this.$t('部署に属していないメンバーは、自動的に「管理」所属に設定されます'),
        this.$t(`部署設定の有効化`),
        {
          showCancelButton: false,
          customClass: 'title-center single-button',
          confirmButtonText: this.$t('確認して閉じる'),
          confirmButtonClass: 'c-button primary no-focus',
        }
      );
    }
  }
}
