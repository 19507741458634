





















































import {Component, Prop} from 'vue-property-decorator';
import AuthViewBase from "@/views/AuthViewBase";
import MemberDepartmentSelect from "@/views/components/Department/MemberDepartmentSelect.vue";
import MypageBodyHeader from "@/views/common/mypage/components/MypageBodyHeader.vue";
import SortableTable from "@/components/Table/SortableTable/SortableTable.vue";
import {CustomValueInputTypeDictForSetting, ICustomValueSetting, ValidatorRules} from "@/entities/interfaces/i-custom-value-setting";
import {CustomValueInputType} from "@/entities/interfaces/i-custom-value";

@Component({
  components: {
    SortableTable,
    MypageBodyHeader,
    MemberDepartmentSelect
  }
})
export default class extends AuthViewBase {
  @Prop({required: true}) data!:ICustomValueSetting[];
  @Prop({default: ''}) propPrefix!:string;
  @Prop({required: false}) title?:string;

  private CustomValueType = CustomValueInputType;
  private CustomValueTypeDictForSetting = CustomValueInputTypeDictForSetting;
  private ValidatorRules = ValidatorRules;

  private created() {
    if (this.data.length === 0) {
      this.add();
    }
  }

  private isEmpty(row: ICustomValueSetting): boolean {
    return !row.title && !row.inputType;
  }
  private add() {
    this.insert(this.data.length);
  }
  private insert(index:number) {
    this.$emit('insert', { data: this.data, index: index });
  }
}
