






























import { Component, Vue, Prop } from 'vue-property-decorator';
import PartnerForm from './PartnerForm.vue';
import {PartnerFactoryBase} from "@/entities/specs/partner-factory-entity";
import InputThumbnailTableItem from "@/components/Form/Thumbnail/InputThumbnailTableItem.vue";
import SpecCertificationEntity from "@/entities/specs/spec-certification-entity";
import {
  Certifications,
  PartnerFactoryCertificationBase,
  ValidatorRules
} from "@/entities/specs/partner-factory-certification-entity";

@Component({
  components: {
    InputThumbnailTableItem,
    PartnerForm,
  },
})
export default class PartnerFactoryForm extends Vue {
  @Prop({required: true}) private factory!:PartnerFactoryBase;
  @Prop({default: false}) private readonly!:boolean;
  @Prop({default: 'factory.'}) private propPrefix!:string;

  private readonly certificationRule = ValidatorRules;
  private readonly Certifications = Certifications;

  created() {
    if(this.factory.certifications.length === 0) {
      this.addCertification();
    }
  }

  private addCertification() {
    // TODO: ちゃんと実装する
    this.factory.certifications.push(new PartnerFactoryCertificationBase());
  }
}
