

















import { Component, Prop} from 'vue-property-decorator';
import execWithLoading from "@/utils/loading-handler";
import AuthViewBase from "@/views/AuthViewBase";
import PartnerForm from "@/views/spec/companies/components/spec-components/create/components/PartnerForm.vue";
import {ValidateWithScroll} from "@/utils/validation-handler";
import SpecSelfSubmitterEntity, {ValidatorRules} from "@/entities/specs/spec-self-submitter-entity";
import SpecSelfSubmitterRepository from "@/repositories/spec/company/spec-self-submitter-repository";
import CanEditButton from "@/views/common/mypage/company/components/CanEditButton.vue";
import MypageBodyHeader from "@/views/common/mypage/components/MypageBodyHeader.vue";

@Component({
  components: {
    MypageBodyHeader,
    PartnerForm,
    CanEditButton
  }
})
export default class extends AuthViewBase {
  @Prop({required: true}) private selfSubmitterId!:number;
  private readonly rules = ValidatorRules;

  private partner:SpecSelfSubmitterEntity|null = null;

  private async created() {
    await execWithLoading(async () => {
      this.partner = await (new SpecSelfSubmitterRepository(this.companyId)).findById(this.selfSubmitterId);
    });
  }

  private async save() {
    if (!(await ValidateWithScroll(this.$refs.form as any))) return;

    execWithLoading(async () => {
      await (new SpecSelfSubmitterRepository(this.companyId)).update(this.selfSubmitterId!, this.partner!);
    }).then(() => {
      this.$message({type: 'success', message: this.$t('保存しました') });
      this.$router.push({name: 'mypage.company.client'});
    });
  }
}
