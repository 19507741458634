import RowKey from "@/entities/concerns/rowkey";
import {CustomValueInputType, CustomValueInputTypeDict, ICustomValue} from "@/entities/interfaces/i-custom-value";
import {ICustomValueSetting} from "@/entities/interfaces/i-custom-value-setting";

export interface CustomValueInnerTableModelType extends ICustomValue {
  $rowKey: number;
  $isFromSetting: boolean;
  $selections?: string[];
}

export function makeModel(value: ICustomValue[], customValueSettings?: ICustomValueSetting[] ): CustomValueInnerTableModelType[] {
  const createRowFromValue = (v: ICustomValue): CustomValueInnerTableModelType => {
    return {
      ...v,
      inputType: CustomValueInputTypeDict.map(s => s.id).includes(v.inputType) ? v.inputType : CustomValueInputType.Text, // もともとSelectが選択されていた場合、設定が削除されている可能性があるので、Textにする
      $isFromSetting: false,
      $rowKey: RowKey.getRowKey(),
    };
  };
  if (customValueSettings) {
    /**
     * ・設定を初期設定項目として表示
     * ・初期設定項目と名前が一致する既存データの値をはめる
     * ・一致しない既存データを下に追加
     */
    const fieldsFromSetting: CustomValueInnerTableModelType[] = customValueSettings.map((v) => {
      const matched = value.find((vv) => vv.title === v.title);
      return {
        ...v,
        value: matched ? matched.value : '',
        value2: matched ? matched.value2 : '',
        $isFromSetting: true,
        $disableDelete: true, // for AddDeleteTable
        $rowKey: RowKey.getRowKey(),
        $selections: v.selections,
      };
    });
    const fieldsFromValue: CustomValueInnerTableModelType[] = value
      .filter((v) => !customValueSettings!.find((vv) => vv.title === v.title))
      .map((v) => createRowFromValue(v));

    return fieldsFromSetting.concat(fieldsFromValue);
  } else {
    return value.map((v) => createRowFromValue(v));
  }
}
