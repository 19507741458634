


















import {Component} from 'vue-property-decorator';
import execWithLoading from "@/utils/loading-handler";
import AuthViewBase from "@/views/AuthViewBase";
import {
  PartnerAllType,
  PartnerAllTypeDict,
  PartnerEntity,
  PartnerType,
  PartnerTypeDict
} from "@/entities/specs/partner-entity";
import PartnerRepository from "@/repositories/spec/company/partner-repository";
import PartnerIndexSection from "./components/PartnerIndexSection.vue";
import SpecSelfSubmitterRepository from "@/repositories/spec/company/spec-self-submitter-repository";
import SpecSelfSubmitterEntity from "@/entities/specs/spec-self-submitter-entity";
import appType, {AppTypes} from "@/app-types";
import MypageBodyHeader from "@/views/common/mypage/components/MypageBodyHeader.vue";

@Component({
    components: {
      MypageBodyHeader,
      PartnerIndexSection
    }
  })
  export default class extends AuthViewBase {
    private readonly PartnerAllTypeDict = PartnerAllTypeDict;

    private partners:PartnerEntity[]|null = null;

    private async created() {
      await execWithLoading(async () => {
        await (new PartnerRepository(this.companyId)).all().then(list => {
          this.partners = list;
        })
      });
    }

    private filterPartners(type: PartnerAllType) {
      if (!this.partners) return [];
      return this.partners.filter(p => p.type === type);
    }
  }
