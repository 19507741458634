

















import { Component, Prop, Vue} from 'vue-property-decorator';
import {RawLocation } from "vue-router/types/router";
import {PartnerBase} from "@/entities/specs/partner-entity-base";

@Component
export default class extends Vue {
  @Prop({required: true}) private partners!: PartnerBase[];
  @Prop({required: true}) private label!: string;
  @Prop({required: true}) private getTo!: (partner:PartnerBase, idx:number) => RawLocation;
}

