import RepositoryBase, {IFindOption} from "@/repositories/repository-base";
import {MadeInAreaEntity} from "@/entities/ingredient-made-in-area-entity";
import CustomCache from "@/utils/custom-cache";

export default class MadeInAreaRepository extends RepositoryBase<MadeInAreaEntity> {
  protected endpoint = "made-in-areas";
  protected ctor:new(data) => MadeInAreaEntity = MadeInAreaEntity;

  protected static cache:CustomCache<MadeInAreaEntity[]> = new CustomCache();
  protected static countryCache:CustomCache<MadeInAreaEntity[]> = new CustomCache();

  public async findAllWithCache(opt?:IFindOption): Promise<MadeInAreaEntity[]> {
    return await this.handleCache(MadeInAreaRepository.cache, () => this.findAll(opt));
  }

  public async getCountries(): Promise<MadeInAreaEntity[]> {
    return await this.handleCache(MadeInAreaRepository.countryCache, async () => {
      const res = await this.get(this.endpoint + '/countries');
      return res.data.map(d => new MadeInAreaEntity(d));
    });
  }
}

import {JapanPrefecturesLang as lang} from "@/lang/enum/japan-prefectures-lang";

export const JapanPrefectures = [
  {id: 2, name: lang.北海道},
  {id: 3, name: lang.青森県},
  {id: 4, name: lang.岩手県},
  {id: 5, name: lang.宮城県},
  {id: 6, name: lang.秋田県},
  {id: 7, name: lang.山形県},
  {id: 8, name: lang.福島県},
  {id: 9, name: lang.茨城県},
  {id: 10, name: lang.栃木県},
  {id: 11, name: lang.群馬県},
  {id: 12, name: lang.埼玉県},
  {id: 13, name: lang.千葉県},
  {id: 14, name: lang.東京都},
  {id: 15, name: lang.神奈川県},
  {id: 16, name: lang.新潟県},
  {id: 17, name: lang.富山県},
  {id: 18, name: lang.石川県},
  {id: 19, name: lang.福井県},
  {id: 20, name: lang.山梨県},
  {id: 21, name: lang.長野県},
  {id: 22, name: lang.岐阜県},
  {id: 23, name: lang.静岡県},
  {id: 24, name: lang.愛知県},
  {id: 25, name: lang.三重県},
  {id: 26, name: lang.滋賀県},
  {id: 27, name: lang.京都府},
  {id: 28, name: lang.大阪府},
  {id: 29, name: lang.兵庫県},
  {id: 30, name: lang.奈良県},
  {id: 31, name: lang.和歌山県},
  {id: 32, name: lang.鳥取県},
  {id: 33, name: lang.島根県},
  {id: 34, name: lang.岡山県},
  {id: 35, name: lang.広島県},
  {id: 36, name: lang.山口県},
  {id: 37, name: lang.徳島県},
  {id: 38, name: lang.香川県},
  {id: 39, name: lang.愛媛県},
  {id: 40, name: lang.高知県},
  {id: 41, name: lang.福岡県},
  {id: 42, name: lang.佐賀県},
  {id: 43, name: lang.長崎県},
  {id: 44, name: lang.熊本県},
  {id: 45, name: lang.大分県},
  {id: 46, name: lang.宮崎県},
  {id: 47, name: lang.鹿児島県},
  {id: 48, name: lang.沖縄県},

]

