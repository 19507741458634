import RepositoryBase from "@/repositories/repository-base";
import UserEntity, {CompanyRole} from "@/entities/user-entity";

export default class CompanyMemberRepository extends RepositoryBase<any> {

  public constructor(companyId:number) {
    super();
    this.endpoint = `companies/${companyId}/members`
  }

  public async updateMember(userId:number, req:CompanyMemberUpdateRequest) {
    return await this.put(this.endpoint + `/${userId}`, req);
  }
}

export type CompanyMemberUpdateRequest = {
  companyRole: CompanyRole;
  canAccessAllDeptResources: boolean;
  departmentIds: number[];
}
