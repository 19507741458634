
























import Base from './input-thumbnail';
import {Component, Prop} from "vue-property-decorator";
import {IFileDefaultValue} from "@/contracts/i-file";
import {i18n} from "@/bootstraps/locale";
@Component
export default class extends Base {
  private remove() {
    this.$emit('input', {
      ...IFileDefaultValue,
      __destroy: true
    });
    this.onRemoved();
  }
}
