import RepositoryBase from "@/repositories/repository-base";
import InvitingMemberEntity from "@/entities/inviting-member-entity";
import {CompanyMemberUpdateRequest} from "@/repositories/company-member-repository";
import appType from "@/app-types";

export class InvitingMemberRepository extends RepositoryBase<InvitingMemberEntity> {
  protected ctor:new(data) => InvitingMemberEntity = InvitingMemberEntity;

  public constructor(companyId) {
    super();
    this.endpoint = `companies/${companyId}/inviting-members`
  }

  public async invite(req:InvitingMemberCreateRequest) {
    return await this.post(this.endpoint, {
      appType,
      ...req
    });
  }
}

export type InvitingMemberCreateRequest = CompanyMemberUpdateRequest & {
  email: string;
}
