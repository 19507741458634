





















































































































import {Component} from 'vue-property-decorator';
import UserEntity, {CompanyRole, CompanyRoleDict, ValidatorRules} from "@/entities/user-entity";
import UserRepository from "@/repositories/user-repository";
import CompanyEntity from "@/entities/company-entity";
import execWithLoading from "@/utils/loading-handler";
import {ValidateWithScroll} from "@/utils/validation-handler";
import {InvitingMemberCreateRequest, InvitingMemberRepository} from "@/repositories/company/inviting-member-repository";
import InvitingMemberEntity from "@/entities/inviting-member-entity";
import AuthViewBase from "@/views/AuthViewBase";
import {CompanyRepository} from "@/repositories/company-repository";
import appType from "@/app-types";
import CompanyMemberRepository, {CompanyMemberUpdateRequest} from "@/repositories/company-member-repository";
import MemberDepartmentSelect from "@/views/components/Department/MemberDepartmentSelect.vue";
import {help as PopoverText} from '@/lang/help/setting';
import MypageBodyHeader from "@/views/common/mypage/components/MypageBodyHeader.vue";

@Component({
  components: {
    MypageBodyHeader,
    MemberDepartmentSelect
  }
})
export default class extends AuthViewBase {
  private readonly CompanyRoleDict = CompanyRoleDict;
  private readonly CompanyRole = CompanyRole;
  private readonly PopoverText = PopoverText;

  private company:CompanyEntity|null = null;

  private invitingMemberDialogVisible = false;
  private invitingMemberCreateRequest:InvitingMemberCreateRequest|null = null;

  private companyMemberUpdateDialogVisible = false;
  private companyMemberUpdateRequest: CompanyMemberUpdateRequest & {memberId: number}|null = null;

  private initialized = false;

  private get rules() {
    return {
      email: ValidatorRules.email,
      companyRole: ValidatorRules.companyRole,
    };
  }

  private async created() {
    await this.load();
    this.initialized = true;
  }

  private async load() {
    await execWithLoading(async () => {
      this.company = await (new CompanyRepository()).findById(this.companyId);
    });
  }

  private showAddingModal() {
    this.invitingMemberCreateRequest = {
      email: '',
      companyRole: CompanyRole.Admin,
      departmentIds: [],
      canAccessAllDeptResources: true,
    };
    this.invitingMemberDialogVisible = true;
  }

  private async deleteMember(user:UserEntity) {
    this.$confirm(
      this.$t(`をメンバーから削除しますか？`, [user.name]),
      this.$t('メンバー削除'),
      {
        confirmButtonText: this.$t('削除'),
        cancelButtonText: this.$t('キャンセル'),
      }
    )
      .then(() => {
        execWithLoading(() => {
          return (new UserRepository).destroy(user.id);
        }).then(() => {
          this.$message({type: 'success', message: this.$t('メンバーを削除しました')});
          this.load();
        });
      })
      .catch(err => { if (err !== 'cancel') throw err; });
  }

  private async removeInvitation(data:InvitingMemberEntity) {
    this.$confirm(
      this.$t(`の招待を取り消しますか？`, [data.email]),
      this.$t('招待取り消し'),
      {
      confirmButtonText: this.$t('招待取り消し'),
      cancelButtonText: this.$t('閉じる'),
    })
      .then(() => {
        execWithLoading(async () => {
          await (new InvitingMemberRepository(this.companyId)).destroy(data.id);
        }).then(() => {
          this.$message({type: 'info', message: this.$t('招待を取り消しました')});
          this.load();
        });
      })
      .catch(err => { if (err !== 'cancel') throw err; });
  }

  private async inviteMember() {
    if (!(await ValidateWithScroll(this.$refs.addingForm as any))) return;

    execWithLoading(async () => {
      return await (new InvitingMemberRepository(this.companyId)).invite(this.invitingMemberCreateRequest!);
    }).then(() => {
      this.invitingMemberCreateRequest = null;
      this.invitingMemberDialogVisible = false;

      this.$message({
        dangerouslyUseHTMLString: true,
        type: 'success',
        message: this.$t('mypage.company.member.invitation_sent')
      });
      this.load();
    });
  }

  private showMemberEditModal(user:UserEntity) {
    this.companyMemberUpdateRequest = {
      memberId: user.id,
      companyRole: user.companyRole,
      departmentIds: user.departments.map(d => d.id),
      canAccessAllDeptResources: user.canAccessAllDeptResources,
    };
    this.companyMemberUpdateDialogVisible = true;
  }

  private async updateMember() {
    if (!(await ValidateWithScroll(this.$refs.updatingForm as any))) return;

    execWithLoading(() => {
      return (new CompanyMemberRepository(this.companyId))
        .updateMember(this.companyMemberUpdateRequest!.memberId, this.companyMemberUpdateRequest!);
    }).then(() => {
      this.companyMemberUpdateDialogVisible = false;
      this.companyMemberUpdateRequest = null;
      this.$message({type: 'success', message: this.$t('メンバーを更新しました')});
      this.load();
    });
  }

}
