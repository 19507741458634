

















import {Component} from 'vue-property-decorator';
import AuthViewBase from "@/views/AuthViewBase";
import MemberDepartmentSelect from "@/views/components/Department/MemberDepartmentSelect.vue";
import MypageBodyHeader from "@/views/common/mypage/components/MypageBodyHeader.vue";
import SortableTable from "@/components/Table/SortableTable/SortableTable.vue";
import CustomValueSettingSection from "@/views/common/mypage/company/components/CustomValueSettingSection.vue";
import CanEditButton from "@/views/common/mypage/company/components/CanEditButton.vue";
import {ValidateWithScroll} from "@/utils/validation-handler";
import execWithLoading from "@/utils/loading-handler";
import {
  ICustomValueSetting,
  serializeCustomValueSetting,
  ValidatorRules
} from "@/entities/interfaces/i-custom-value-setting";
import PartnerIntraCustomValueSettingEntity from "@/entities/partner-intra-custom-value-setting-entity";
import {PartnerIntraCustomValueSettingRepository} from "@/repositories/company/partner-intra-custom-value-setting-repository";
import PartnerFactoryIntraCustomValueSettingEntity from "@/entities/partner-factory-intra-custom-value-setting-entity";
import {
  PartnerFactoryIntraCustomValueSettingRepository
} from "@/repositories/company/partner-factory-intra-custom-value-setting-repository";
import LoadingHandler from "@/utils/loading-handler";
import {CompanyIntraCategoryProductRepository} from "@/repositories/company/company-intra-category-product-repository";

@Component({
  components: {
    CanEditButton,
    CustomValueSettingSection,
    SortableTable,
    MypageBodyHeader,
    MemberDepartmentSelect
  }
})
export default class extends AuthViewBase {
  private ValidatorRules = ValidatorRules;

  // ElFormはarrayは受け付けない
  private model: {data: PartnerFactoryIntraCustomValueSettingEntity[] } = {
    data: []
  };
  private initialized:boolean = false;

  private async created() {
    await this.load();
  }
  private async load() {
    await execWithLoading(async () => {
      this.initialized = false;
      await Promise.all([
        (new PartnerFactoryIntraCustomValueSettingRepository(this.companyId)).index()
          .then(list => {
            this.model.data = list;
          })
      ]);
      this.initialized = true;
    });
  }

  private insert(ev: { data:ICustomValueSetting[], index:number } ) {
    const newOne = new PartnerFactoryIntraCustomValueSettingEntity({
      inputType: null as any,
    });
    ev.data.splice(ev.index, 0, newOne);
  }

  private async save() {
    if (!(await ValidateWithScroll(this.$refs.form as any))) return;

    execWithLoading(async () => {
      const data = serializeCustomValueSetting(this.model.data) as PartnerFactoryIntraCustomValueSettingEntity[];
      await (new PartnerFactoryIntraCustomValueSettingRepository(this.companyId)).save(data);
    }).then(() => {
      this.$message({type: 'success', message: this.$t('保存しました') });
      this.load();
    });
  }
}
