


































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Subscription, SubscriptionStatus} from "@/repositories/company/payment-repository";
import {AppTypes} from "@/app-types";

@Component
export default class extends Vue {
  @Prop({required: true}) private type!:AppTypes;
  @Prop({required: true}) private subscription!:Subscription;

  private readonly SubscriptionStatus = SubscriptionStatus;

  private get title() {
    return this.$t(this.type === AppTypes.Label ? 'スマート食品表示' : 'スマート食品規格書');
  }
  private get color() {
    return this.type === AppTypes.Label ? 'var(--color-primary--label)' : 'var(--color-primary--spec)';
  }
  private get price():number {
    return this.subscription.plan.isFreePlan ? 0 : this.subscription.plan.amount;

  }
}
