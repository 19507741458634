import RepositoryBase, {IFindOption} from "@/repositories/repository-base";

import {
  ApprovalFlowSettingEntity,
  ApprovalFlowSettingStepCompletionType, ApprovalFlowSettingStepEntity
} from "@/entities/approval-flow-setting-entity";
import {ApprovalFlowSettingDeclineType} from "@/entities/company-setting-entity";

export class ApprovalFlowSettingRepository extends RepositoryBase<ApprovalFlowSettingEntity> {
  protected companyId!:number;

  public constructor(companyId) {
    super();
    this.companyId = companyId;
    this.endpoint = `companies/${companyId}/settings/approval-flow-settings`
  }

  public async updateMetaSetting(data:UpdateApprovalFlowMetaSettingRequest) {
    return this.put(this.endpoint, data);
  }
  public async createFlow(forAcceptance: boolean, data:UpdateApprovalFlowSettingRequest): Promise<number> {
    const res = await this.post(this.endpoint, { forAcceptance, ...data });
    return res.data.id;
  }
  public async updateFlow(flowId:number, data:UpdateApprovalFlowSettingRequest) {
    return this.put(this.endpoint + `/${flowId}`, data);
  }
  public async deleteFlow(flowId: number) {
    return this.delete(this.endpoint + `/${flowId}`);
  }

  public async findStep(flowId:number, flowStepId: number): Promise<ApprovalFlowSettingStepEntity> {
    const res = await this.get(this.endpoint + `/${flowId}/steps/${flowStepId}`);
    return new ApprovalFlowSettingStepEntity(res.data);
  }
  public async createStep(flowId:number, data: UpdateApprovalFlowSettingStepRequest): Promise<ApprovalFlowSettingStepEntity> {
    const res = await this.post(this.endpoint + `/${flowId}/steps/`, data);
    return new ApprovalFlowSettingStepEntity(res.data);
  }
  public async updateStep(flowId:number, stepId:number, data: UpdateApprovalFlowSettingStepRequest) {
    return await this.put(this.endpoint + `/${flowId}/steps/${stepId}`, data);
  }
  public async deleteStep(flowId:number, flowStepId: number) {
    return this.delete(this.endpoint + `/${flowId}/steps/${flowStepId}`);
  }
}

export type UpdateApprovalFlowMetaSettingRequest = {
  approvalFlowForAcceptanceEnabled: boolean;
  approvalFlowForAcceptanceDeclineType: ApprovalFlowSettingDeclineType;
  approvalFlowForAcceptanceIsEditableOnProgress: boolean;
  approvalFlowForOwnedSpecEnabled: boolean;
  approvalFlowForOwnedSpecDeclineType: ApprovalFlowSettingDeclineType;
  approvalFlowForOwnedSpecIsEditableOnProgress: boolean;
};

export type UpdateApprovalFlowSettingRequest = {
  name: string;
  departmentIds: number[];
};

export type UpdateApprovalFlowSettingStepRequest = {
  name: string;
  completionConditionType: ApprovalFlowSettingStepCompletionType;
  groups: UpdateApprovalFlowSettingStepGroupRequest[];
};
export type UpdateApprovalFlowSettingStepGroupRequest = {
  $isAllRequired: boolean;
  requiredApprovalNumber: number | null;
  userIds: number[];
};
