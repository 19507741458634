










































































import {Component, Prop} from 'vue-property-decorator';
import execWithLoading from "@/utils/loading-handler";
import AuthViewBase from "@/views/AuthViewBase";
import MypageBodyHeader from "@/views/common/mypage/components/MypageBodyHeader.vue";
import MyPageBodySection from "@/views/common/mypage/components/MyPageBodySection.vue";
import MyPageBodySettingRow from "@/views/common/mypage/components/MyPageBodySettingRow.vue";
import BooleanSelect from "@/components/Form/BooleanSelect.vue";
import {CompanyRepository} from "@/repositories/company-repository";
import CompanyEntity from "@/entities/company-entity";
import CanEditButton from "@/views/common/mypage/company/components/CanEditButton.vue";
import {
  ApprovalFlowSettingRepository, UpdateApprovalFlowSettingStepGroupRequest,
  UpdateApprovalFlowSettingStepRequest
} from "@/repositories/company/approval-flow-setting-repository";
import {
  ApprovalFlowSettingEntity,
  ApprovalFlowSettingStepCompletionType, ApprovalFlowSettingStepCompletionTypeDict, ApprovalFlowSettingStepEntity
} from "@/entities/approval-flow-setting-entity";
import {HandledApiError} from "@/bootstraps/error-handler";
import { help } from '@/lang/help/setting';
import {createMinRule, MAX_SIGNED_INT_VALUE, requiredOnBlurRule} from "@/utils/validation-rules";
import RowKey from "@/entities/concerns/rowkey";
import { ValidateWithScroll } from '@/utils/validation-handler';
import {i18n} from "@/bootstraps/locale";

@Component({
  components: {
    CanEditButton,
    BooleanSelect,
    MyPageBodySettingRow, MyPageBodySection,
    MypageBodyHeader,
  }
})
export default class extends AuthViewBase {
  @Prop({required: true}) private approvalFlowSettingId!:number;
  @Prop({required: false}) private approvalFlowSettingStepId?:number;

  private readonly ApprovalFlowSettingStepCompletionTypeDict = ApprovalFlowSettingStepCompletionTypeDict;
  private readonly help = help;

  private initialized = false;
  private company: CompanyEntity = null as any;
  private flow: ApprovalFlowSettingEntity = null as any;
  private step: ApprovalFlowSettingStepEntity | null = null as any;

  private model: UpdateApprovalFlowSettingStepRequest = {
    name: '',
    completionConditionType: ApprovalFlowSettingStepCompletionType.And,
    groups: [] as UpdateApprovalFlowSettingStepGroupRequest[],
  };

  private get rules() {
    const self = this;
    return {
      name: [ requiredOnBlurRule ],
      groups: {
        requiredApprovalNumber: [ requiredOnBlurRule, createMinRule(1) ],
        userIds: [
          createMinRule(1, 'array'),
          {
            trigger: 'none',
            validator: (rule, value, callback) => {
              const idx = Number(/groups\.(\d+)\.userIds/.exec(rule.field)![1]);
              const requiredNum = self.model.groups[idx].requiredApprovalNumber;
              if(requiredNum) {
                if(value.length < requiredNum) {
                  return callback(new Error('承認者の候補は指定人数より多く指定してください'));
                }
              }
              return callback();
            }
          }
        ]
      },
    }
  };

  protected get isCreateMode(): boolean {
    return !this.approvalFlowSettingStepId;
  }

  private async created() {
    await this.load();
    this.initialized = true;
  }

  private async load() {
    await execWithLoading(async () => {
      return Promise.all([
        (new CompanyRepository).find(this.companyId).then(res=> {
          this.company = res;
        }),
      ]);
    });

    const flow = this.company.approvalFlowSettings.find(f => f.id === Number(this.approvalFlowSettingId));
    if (!flow) {
      throw new HandledApiError('自社内に存在しない承認ワークフローIDが指定されています。');
    }
    this.flow = flow;

    if (this.approvalFlowSettingStepId) {
      const step = this.flow.steps.find(s => s.id === Number(this.approvalFlowSettingStepId));
      if (!step) {
        throw new HandledApiError('自社内に存在しない承認ワークフローステップIDが指定されています。');
      }

      this.step = step;
      this.model = {
        name: step.name,
        completionConditionType: step.completionConditionType,
        groups: step.groups.map(g => {
          return {
            $isAllRequired: g.$isAllRequired,
            requiredApprovalNumber: g.requiredApprovalNumber,
            userIds: g.users.map(u => u.userId),
          };
        })
      };
    } else {
      this.model.groups.push({
        $isAllRequired: true,
        requiredApprovalNumber: null,
        userIds: [],
      });
    }
  }

  private addGroup() {
    this.model.groups.push({
      $isAllRequired: true,
      requiredApprovalNumber: null,
      userIds: [],
    });
  }
  private async removeGroup(idx: number) {
    if (this.model.groups[idx].userIds.length > 0) {
      const confirm = await this.$confirm2('この承認グループを削除しますか？', '確認');
      if (!confirm) return;
    }
    this.model.groups.splice(idx, 1);
  }

  private isAllRequiredChange(val: boolean, g: UpdateApprovalFlowSettingStepGroupRequest) {
    if (val) {
      g.requiredApprovalNumber = null;
    } else {
      g.requiredApprovalNumber = 1;
    }
  }

  private async submit() {
    if (!this.model.groups.length) {
      this.$message( {type: 'warning', message: this.$t('承認グループを一つ以上指定してください。') });
      return;
    }

    if (!(await ValidateWithScroll(this.$refs.form as any))) return;

    await execWithLoading(async () => {
      if (this.isCreateMode) {
        const step = await (new ApprovalFlowSettingRepository(this.companyId)).createStep(this.approvalFlowSettingId, this.model);
        await this.$router.push({name: 'mypage.company.approval.flow.step.edit', params: { approvalFlowSettingStepId: step.id as any }});
      } else {
        await (new ApprovalFlowSettingRepository(this.companyId)).updateStep(this.approvalFlowSettingId, this.approvalFlowSettingStepId!, this.model);
      }

      await this.load();
      this.$message( {type: 'success', message: this.$t('保存しました（開始済みのワークフローには影響しません）') });
    });
  }
}
