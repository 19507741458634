














import {Component, Vue} from 'vue-property-decorator';
import MypageContainer from "@/views/common/mypage/MypageContainer.vue";

@Component({
  components: {MypageContainer}
})
export default class extends Vue {
}
