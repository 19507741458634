



































































































import {Component} from 'vue-property-decorator';
import LoadingHandler from '@/utils/loading-handler';
import {tokenErrors} from '@/libs/payjp';
import {CardInfo} from '@/utils/credit-card-utils';

import PaymentRepository, {
  PaymentInfo,
  Subscription,
  SubscriptionStatus
} from "@/repositories/company/payment-repository";
import CreditCardFormPayJP from '@/components/CreditCardFormPayJP.vue';
import PlanCard from './components/PlanCard.vue';
import PlanCardFreeSpec from './components/PlanCardFreeSpec.vue';

import formatDate from 'date-fns/format';
import Qs from 'qs';
import {DIContainer} from "@/utils/di-container";
import AuthViewBase from "@/views/AuthViewBase";
import appType, {AppTypes} from "@/app-types";
import {HandledApiError} from "@/bootstraps/error-handler";
import MypageBodyHeader from "@/views/common/mypage/components/MypageBodyHeader.vue";

  @Component({
    components: {
      MypageBodyHeader,
      CreditCardFormPayJP,
      PlanCard,
      PlanCardFreeSpec
    },
  })
  export default class extends AuthViewBase {
    private payment:PaymentInfo|null = null;

    private editingCard = {
      isEditing: false,
      inputCompleted: false,
      tokenError: ''
    };

    private async created() {
      await LoadingHandler(async () => {
        await this.reloadPayment();
      });
    }
    private async reloadPayment() {
      this.payment = await (new PaymentRepository(this.companyId)).getPaymentInfo();
    }

    private cardChanged(completed: boolean) {
      this.editingCard.inputCompleted = completed;
    }
    public changeCard() {
      LoadingHandler(async () => {
        let token:any = {};

        try {
          const elem = (this.$refs.creditCard as any).payjpElement;
          token = await DIContainer.payjp.createToken(elem);
        } catch (error) {
          this.editingCard.tokenError = this.$t("トークン作成に失敗しました") + "（code:" + tokenErrors[error.error.code] + "）";
          throw new HandledApiError(this.editingCard.tokenError);
        }

        await (new PaymentRepository(this.companyId)).changeCard(token.id)
          .catch(error => {
            this.editingCard.tokenError = this.$t("クレジットカードの登録に失敗しました") + "（" + error + "）";
            throw error;
          });

      }).then(() => {
        this.$message({
          type: 'success',
          message: this.$t('カード情報を更新しました') + '. ' + this.$t('画面をリロードします'),
        });
        setTimeout(() => location.reload(), 500);
      });
    }

    private suspend(subscription:Subscription) {
      const until = formatDate(subscription.nextPaymentDate, 'yyyy/MM/dd');

      const title = this.$t(subscription.type === AppTypes.Label ? "食品表示プランの解約" : "規格書プランの解約");
      const msg = subscription.type === AppTypes.Label
        ? this.$t('mypage.company.invoice.cancel_label', [ until ])
        : this.$t('mypage.company.invoice.cancel_spec', [ until ]);

      this.$confirm(msg as string, title, {
        confirmButtonText: this.$t('了承して解約'),
        confirmButtonClass: 'c-button primary-inverse no-focus cy-confirm',
        cancelButtonText: this.$t('閉じる'),
        dangerouslyUseHTMLString: true
      })
        .then(async () => {
          await LoadingHandler(async () => {
            await (new PaymentRepository(this.companyId, subscription.type)).suspendSubscription()
              .catch(error => {
                this.$message({
                  type: 'error',
                  message: this.$t("プランの解約に失敗しました") + "（" + error.message + "）",
                  showClose: true
                });

                throw error;
              });

            await this.reloadPayment();
            this.$message({type: 'success', message: this.$t(`プランを解約しました。`) + `（${this.$t("mypage.company.invoice.can_use_until", [until])}）`});
          });
        })
        .catch(err => { if (err !== 'cancel') throw err; });
    }

    private resume(subscription:Subscription) {
      const next = formatDate(subscription.nextPaymentDate, 'yyyy/MM/dd');

      const title = this.$t(subscription.type === AppTypes.Label ? "食品表示プランの再契約" : "規格書プランの再契約");

      const msg = subscription.getStatus() === SubscriptionStatus.Inactive ?
        this.$t('mypage.company.invoice.canceled', [ next ]) :
        subscription.type === AppTypes.Label ?
          this.$t('mypage.company.invoice.resume_label') :
          this.$t('mypage.company.invoice.resume_spec');

      this.$confirm(msg as string, title, {
        confirmButtonText: this.$t('再契約'),
        confirmButtonClass: 'c-button primary no-focus cy-confirm',
        cancelButtonText: this.$t('閉じる'),
        cancelButtonClass: 'c-button primary-inverse no-focus',
        dangerouslyUseHTMLString: true
      })
        .then(async () => {
          await LoadingHandler(async () => {
            try {
              await (new PaymentRepository(this.companyId, subscription.type)).resumeSubscription();
              await this.reloadPayment();
              this.$message({type: 'success', message: this.$t(`プランを再開しました。`)});
            } catch (err) {
              this.$message({type: 'error', message: this.$t("プランの再開に失敗しました") + "（" + err.message + "）"});
              throw err;
            }
          });
        })
        .catch(err => { if (err !== 'cancel') throw err; });
    }

    private showInvoice(charge:any) {
      const url = "/report/invoice?" + Qs.stringify(charge);
      window.open(url, "_blank");
    }
  }
