import RepositoryBase, {IFindOption} from "@/repositories/repository-base";

export class SatoRepository extends RepositoryBase<any> {
  protected companyId!:number;

  public constructor(companyId) {
    super();
    this.companyId = companyId;
    this.endpoint = `companies/${companyId}/settings/sato`
  }

  public async getWebdavPassword(): Promise<string> {
    const res = await this.get(this.endpoint + '/password');
    return res.data;
  }
  public async renewWebdavPassword(): Promise<string> {
    const res = await this.put(this.endpoint + '/password/renew');
    return res.data;
  }
}
