















































import {Component} from 'vue-property-decorator';
import execWithLoading from "@/utils/loading-handler";
import AuthViewBase from "@/views/AuthViewBase";
import ElTableDraggable from "@/components/Table/SortableTable/ElTableDraggable.vue";
import {CompanyIntraCategoryProductEntity, ValidatorRules} from "@/entities/company-intra-category-product-entity";
import {CompanyIntraCategoryProductRepository} from "@/repositories/company/company-intra-category-product-repository";
import LoadingHandler from "@/utils/loading-handler";
import EditNameModal from "@/views/common/mypage/company/IntraCategory/EditNameModal.vue";
import SortableTable from "@/components/Table/SortableTable/SortableTable.vue";
import MypageBodyHeader from "@/views/common/mypage/components/MypageBodyHeader.vue";

@Component({
  components: {
    MypageBodyHeader,
    SortableTable,
    EditNameModal,
    ElTableDraggable,
  }
})
export default class extends AuthViewBase {
  private readonly ValidatorRules = ValidatorRules;
  private productCategories:CompanyIntraCategoryProductEntity[]|null = null;

  private addModal = {
    visible: false,
  };
  private updateModal = {
    visible: false,
    selected: null as CompanyIntraCategoryProductEntity | null,
  };

  private async created() {
    await execWithLoading(async () => {
      await this.load();
    });
  }
  private async load() {
    return Promise.all([
      (new CompanyIntraCategoryProductRepository(this.companyId)).all().then(list => {
        this.productCategories = list;
      }),
    ]);
  }

  private showAddModal() {
    this.addModal = { visible: true };
  }
  private async add( data: { id: null, name: string } ) {
    return LoadingHandler(async () => {
      const req = new CompanyIntraCategoryProductEntity({name: data.name });
      await (new CompanyIntraCategoryProductRepository(this.companyId)).add(req);
    }).then(() => {
      this.addModal.visible = false;
      this.$message({ type: 'success', message: this.$t("追加しました") });
      this.load();
    });
  }
  private showUpdateModal(row: CompanyIntraCategoryProductEntity) {
    this.updateModal = { visible: true, selected: row,};
  }
  private async update(data: { id: number, name: string }) {
    return LoadingHandler(async () => {
      await (new CompanyIntraCategoryProductRepository(this.companyId)).updateName(data.id, data.name);
      await this.load();
    }).then(() => {
      this.updateModal.visible = false;
      this.$message({ type: 'success', message: this.$t("編集しました") });
    });
  }

  private showDeleteModal(row: CompanyIntraCategoryProductEntity) {
    const msg = '商品カテゴリを削除すると、商品カテゴリを設定した、すべての商品から商品カテゴリが削除されます。<br/><br/>本当に削除してもよろしいですか。';
    this.$confirm(msg, '自社商品カテゴリの追加', {
      customClass: 'danger',
      confirmButtonText: this.$t('同意して削除'),
      confirmButtonClass: 'c-button danger no-focus',
      cancelButtonText: this.$t('キャンセル'),
      cancelButtonClass: 'c-button danger-inverse no-focus',
      dangerouslyUseHTMLString: true
    })
      .then(async () => {
        await LoadingHandler(async () => {
          await (new CompanyIntraCategoryProductRepository(this.companyId)).remove(row.id);
          await this.load();
        });
      })
      .catch(err => { if (err !== 'cancel' && err !== 'close' ) throw err; });
  }


  private changeOrder( row: CompanyIntraCategoryProductEntity, order:number) {
    return LoadingHandler(async () => {
      await (new CompanyIntraCategoryProductRepository(this.companyId)).updateSortOrder(row.id, order + 1);
      await this.load();
    });
  }
}
