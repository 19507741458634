





















import {Component, Prop, Vue} from 'vue-property-decorator';
import ElTableDraggable from "@/components/Table/SortableTable/ElTableDraggable.vue";
import EditNameModal from "@/views/common/mypage/company/IntraCategory/EditNameModal.vue";
import SortableDropdown from "@/components/Table/SortableTable/SortableDropdown.vue";
import {listenCursorInputOnTable} from "@/components/mixins/TableCursorHandler";
import AddSection from "@/components/Table/AddSection.vue";

@Component({
  components: {
    AddSection,
    SortableDropdown,
    EditNameModal,
    ElTableDraggable,
  }
})
export default class IntraCategoryTable<T extends { sortOrder: number}> extends Vue {
  @Prop({required: true}) private dataList!: T[];
  @Prop({default: false}) private showEdit!: boolean;
  @Prop({default: false}) private showAdd!: boolean;
  @Prop({default: true}) private allowEmpty!:boolean;
  @Prop({default: false}) private handleDeleteManually!: boolean;

  @Prop({default: false}) private withMargin!:boolean;
  @Prop({default: false}) private withMarginBottom!:boolean;

  private mounted() {
    listenCursorInputOnTable(this.$el);
  }

  private add() {
    this.$emit('add');
  }
  private insert(row: T) {
    const index = this.dataList.indexOf(row);
    this.$emit('insert', index + 1);
  }
  private edit( row: T ) {
    this.$emit('edit', row);
  }
  private deleteRow(data: T) {
    if(!this.allowEmpty && this.dataList.length === 1) return;
    if (this.handleDeleteManually) {
      this.$emit('delete', data);
    } else {
      const index = this.dataList.indexOf(data);
      this.dataList.splice(index, 1);
      this.$emit('deleted', data);
    }
  }

  private swap(idx1, idx2) {
    const targetRow = this.dataList.splice(idx1, 1)[0];
    this.dataList.splice(idx2, 0, targetRow);
  }
  private orderUp( row: T ) {
    const currentIndex = this.dataList.indexOf(row);
    this.swap(currentIndex, currentIndex - 1);
    this.changeOrder(row, currentIndex + -1);
  }
  private orderDown( row: T ) {
    const currentIndex = this.dataList.indexOf(row);
    this.swap(currentIndex, currentIndex + 1);
    this.changeOrder(row, currentIndex + 1);
  }
  private onSortChanged( data: { targetObject: T, list: T[] }) {
    const newOrder = this.dataList.indexOf(data.targetObject) + 1;
    this.changeOrder(data.targetObject, newOrder);
  }

  private changeOrder(row: T, newOrder:number) {
    this.$emit('sort', row, newOrder);
  }
}
