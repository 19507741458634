












import { Component, Vue, Prop } from 'vue-property-decorator';
import {Currencies} from "@/entities/currencies";

@Component({
  model: { prop: 'value', event: 'input' }
})
export default class extends Vue {
  @Prop({required: true}) private value!:string;
  @Prop({default: 'default'}) private size!: 'default' | 'small' | 'medium' | 'mini';

  private Currencies = Currencies;

  private get model() { return this.value; }
  private set model(val: string) { this.$emit('input', val); }
}
