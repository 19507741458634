















































































































import { Component, Prop} from 'vue-property-decorator';
import execWithLoading from "@/utils/loading-handler";
import AuthViewBase from "@/views/AuthViewBase";
import {MakerType, PartnerEntity, ValidatorRules} from "@/entities/specs/partner-entity";
import PartnerRepository from "@/repositories/spec/company/partner-repository";
import PartnerForm from "@/views/spec/companies/components/spec-components/create/components/PartnerForm.vue";
import PartnerFactoryForm from "@/views/spec/companies/components/spec-components/create/components/PartnerFactoryForm.vue";
import CanEditButton from "@/views/common/mypage/company/components/CanEditButton.vue";

import {ValidateWithScroll} from "@/utils/validation-handler";
import MypageBodyHeader from "@/views/common/mypage/components/MypageBodyHeader.vue";
import CurrencySelect from "@/components/Form/CurrencySelect.vue";
import cloneDeep from "lodash/cloneDeep";
import CustomValueInnerTable from "@/views/spec/companies/components/CustomValue/CustomValueInnerTable.vue";
import CompanyEntity from "@/entities/company-entity";
import {CompanyRepository} from "@/repositories/company-repository";
import PartnerFactoryIntraCustomValueEntity from "@/entities/specs/partner-factory-intra-custom-value-entity";
import PartnerIntraCustomValueEntity from "@/entities/specs/partner-intra-custom-value-entity";
import {ICustomValue, isCustomValueEmpty} from "@/entities/interfaces/i-custom-value";
import {PartnerFactoryEntity} from "@/entities/specs/partner-factory-entity";

@Component({
  components: {
    CustomValueInnerTable,
    CurrencySelect,
    MypageBodyHeader,
    PartnerForm,
    PartnerFactoryForm,
    CanEditButton
  }
})
export default class extends AuthViewBase {
  @Prop({required: true}) private partnerId!:number;
  private readonly rules = ValidatorRules;

  private partner:PartnerEntity = null as any;
  private company:CompanyEntity = null as any;

  private initialized:boolean = false;

  private async created() {
    await execWithLoading(async () => {
      await Promise.all([
        (new PartnerRepository(this.companyId)).find(this.partnerId!).then(res => {
          this.partner = res;
        }),
        (new CompanyRepository().find(this.companyId)).then(company => {
          this.company = company;
        })
      ]).then(() => {
        this.initialized = true;
      })
    });
  }

  private async save() {
    if (!(await ValidateWithScroll(this.$refs.form as any))) return;

    execWithLoading(async () => {
      const data = this.serialize();
      await (new PartnerRepository(this.companyId)).update(this.partnerId!, data);
    }).then(() => {
      this.$router.push({name: 'mypage.company.partner'}).then(() => {
        this.$message({type: 'success', message: this.$t('保存しました')});
      });
    });
  }

  private addFactory() {
    this.partner.factories.push(
      new PartnerFactoryEntity(),
    )
  }
  private removeFactory(factory: PartnerFactoryEntity, idx: number) {
    this.partner.factories.splice(idx, 1);
  }

  private addPartnerCustomValueRow(): ICustomValue {
    return new PartnerIntraCustomValueEntity();
  }
  private addFactoryCustomValueRow() {
    return new PartnerFactoryIntraCustomValueEntity();
  }

  public get canHaveFactory():boolean {
    return this.partner.type === MakerType.Manufacturer ||
      this.partner.type === MakerType.Processor;
  }

  private serialize(): PartnerEntity {
    const data = cloneDeep(this.partner) as PartnerEntity;

    data.intraCustomValues = data.intraCustomValues.filter(c => !isCustomValueEmpty(c));
    data.factories.forEach(factory => {
      factory.certifications = factory.certifications.filter(c => !c.isEmpty);
      factory.intraCustomValues = factory.intraCustomValues.filter(c => !isCustomValueEmpty(c));
    });
    return data;
  }

  private destroy() {
    this.$confirm(
      this.$t(`このデータを削除します。<br><br>このデータを削除しても、過去にこの関連事業者を使って配信された規格書の内容は、変更されません。`),
      this.$t('関連事業者の削除'),
      {
        dangerouslyUseHTMLString: true,
        confirmButtonText: this.$t('承諾して削除'),
        cancelButtonText: this.$t('キャンセル'),
      }
    ).then(() => {
      execWithLoading(async () => {
        await (new PartnerRepository(this.companyId)).destroy(this.partnerId!);
      }).then(() => {
        this.$router.push({name: 'mypage.company.partner'}).then(() => {
          this.$message({type: 'info', message: this.$t('削除しました')});
        });
      });
    }).catch(err => { if (err !== 'cancel') throw err; });
  }
}
