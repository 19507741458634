












































































import {Component} from 'vue-property-decorator';
import execWithLoading from "@/utils/loading-handler";
import AuthViewBase from "@/views/AuthViewBase";
import MypageBodyHeader from "@/views/common/mypage/components/MypageBodyHeader.vue";
import MyPageBodySection from "@/views/common/mypage/components/MyPageBodySection.vue";
import MyPageBodySettingRow from "@/views/common/mypage/components/MyPageBodySettingRow.vue";
import BooleanSelect from "@/components/Form/BooleanSelect.vue";
import { CompanyRepository } from "@/repositories/company-repository";
import CompanyEntity from "@/entities/company-entity";
import CanEditButton from "@/views/common/mypage/company/components/CanEditButton.vue";
import WorkflowTable from "@/views/common/mypage/company/Approval/components/WorkflowTable.vue";
import {ApprovalFlowSettingDeclineTypeDict} from "@/entities/company-setting-entity";
import { help } from '@/lang/help/setting';
import { ApprovalFlowSettingEntity } from "@/entities/approval-flow-setting-entity";
import {
  ApprovalFlowSettingRepository,
  UpdateApprovalFlowMetaSettingRequest
} from "@/repositories/company/approval-flow-setting-repository";

@Component({
  components: {
    WorkflowTable,
    CanEditButton,
    BooleanSelect,
    MyPageBodySettingRow, MyPageBodySection,
    MypageBodyHeader,
  }
})
export default class extends AuthViewBase {
  private company:CompanyEntity = null as any;

  private initialized = false;
  private globalSettingModel: UpdateApprovalFlowMetaSettingRequest = null as any;

  private readonly ApprovalFlowSettingDeclineTypeDict = ApprovalFlowSettingDeclineTypeDict;
  private readonly help = help;

  private async created() {
    this.load();
  }

  private async load() {
    this.initialized = false;

    await execWithLoading(async () => {
      return Promise.all([
        (new CompanyRepository).find(this.companyId).then(res=> {
          this.company = res;
        }),
      ]);
    });

    this.globalSettingModel = {
      approvalFlowForAcceptanceEnabled: this.company.setting.approvalFlowForAcceptanceEnabled,
      approvalFlowForAcceptanceDeclineType: this.company.setting.approvalFlowForAcceptanceDeclineType,
      approvalFlowForAcceptanceIsEditableOnProgress: this.company.setting.approvalFlowForAcceptanceIsEditableOnProgress,
      approvalFlowForOwnedSpecEnabled: this.company.setting.approvalFlowForOwnedSpecEnabled,
      approvalFlowForOwnedSpecDeclineType: this.company.setting.approvalFlowForOwnedSpecDeclineType,
      approvalFlowForOwnedSpecIsEditableOnProgress: this.company.setting.approvalFlowForOwnedSpecIsEditableOnProgress,
    };

    this.initialized = true;
  }

  private async submitGlobalSetting() {
    await execWithLoading(async () => {
      await (new ApprovalFlowSettingRepository(this.companyId)).updateMetaSetting(this.globalSettingModel);
      await this.load();
    });
    this.$message( {type: 'success', message: this.$t('保存しました') });
  }

  private async remove(flow: ApprovalFlowSettingEntity) {
    const msg = this.$t('xを削除しますか？', [ flow.name ]) + this.$t('（開始済みのワークフローには影響しません）');
    this.$confirm(msg, this.$t('確認'), {
      customClass: 'danger',
      confirmButtonText: this.$t('はい'),
      confirmButtonClass: 'c-button danger no-focus',
      cancelButtonText: this.$t('キャンセル'),
      cancelButtonClass: 'c-button danger-inverse no-focus',
    }).then(async () => {
      await execWithLoading(async () => {
        await (new ApprovalFlowSettingRepository(this.companyId)).deleteFlow(flow.id);
        await this.load();
      });
      this.$message( {type: 'info', message: this.$t('削除しました') });
    }).catch(err => { if (err !== 'cancel') throw err; });
  }
}
