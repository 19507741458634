import ObjectUtils from "@/utils/object-utils";
import {createDecimalRule, createMaxRule, createMinRule, requiredRule, typeNumberRule} from "@/utils/validation-rules";

export class CompanyIntraCategoryProductEntity {
  public id!:number;
  public name!: string;
  public sortOrder!: number;
  public usedProductsCount!: number;

  constructor(init:Partial<CompanyIntraCategoryProductEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);
  }
}

export const ValidatorRules:any = {
  name: [requiredRule, createMaxRule(255, 'char')],
};
