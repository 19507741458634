



























import { Component, Prop} from 'vue-property-decorator';
import LoadingHandler from '@/utils/loading-handler';

import {ValidatorRules} from '@/entities/user-entity';
import {ValidateWithScroll} from "@/utils/validation-handler";
import AuthRepository from "@/repositories/auth-repository";
import getPasswordRules from "@/views/common/auth/ForgetPassword/password-rule";
import AuthViewBase from "@/views/AuthViewBase";
import MypageBodyHeader from "@/views/common/mypage/components/MypageBodyHeader.vue";

@Component({
  components: {MypageBodyHeader}
})
export default class extends AuthViewBase {
  private data = {
    current: "",
    password: "",
    passwordConfirm: "",
  };

  private rules = {
    current: ValidatorRules.password,
    ...getPasswordRules(this.data)
  };

  private async submit() {
    if (!(await ValidateWithScroll(this.$refs.form as any))) return;

    LoadingHandler(async () => {
      await (new AuthRepository()).changePassword(this.data.current, this.data.password);
    }).then(() => {
      this.$message({type: 'success', message: this.$t('パスワードを更新しました')});
      (this.$refs.form as any).resetFields();
    });
  }

}
