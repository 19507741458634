












import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class extends Vue {
  @Prop({required: true}) private title!: string;
  @Prop({required: false}) private breadcrumb?: string;
  @Prop({required: false}) private subtitle?: string;
}
