






















import {Component} from 'vue-property-decorator';
import AuthViewBase from "@/views/AuthViewBase";
import MemberDepartmentSelect from "@/views/components/Department/MemberDepartmentSelect.vue";
import MypageBodyHeader from "@/views/common/mypage/components/MypageBodyHeader.vue";
import execWithLoading from "@/utils/loading-handler";
import CompanyEntity from "@/entities/company-entity";
import { CompanyRepository } from '@/repositories/company-repository';
import {SatoRepository} from "@/repositories/company/sato-repository";
import SimpleFormItem from "@/components/Form/SimpleFormItem.vue";

@Component({
  components: {
    SimpleFormItem,
    MypageBodyHeader,
    MemberDepartmentSelect
  }
})
export default class extends AuthViewBase {
  private company: CompanyEntity = null as any;
  private password: string | null = null;

  private initialized:boolean = false;

  private async created() {
    await this.load();
  }
  private async load() {
    await execWithLoading(async () => {
      this.initialized = false;
      await Promise.all([
        (new CompanyRepository()).find(this.companyId).then(company => {
          this.company = company;
        }),
        (new SatoRepository(this.companyId)).getWebdavPassword().then(res=> {
          this.password = res;
        })
      ]);
      this.initialized = true;
    });
  }

  private async renewPassword () {
    await execWithLoading(async () => {
      const res = await (new SatoRepository(this.companyId)).renewWebdavPassword();
      this.password = res;
      this.$message({type: 'success', message: this.$t("パスワードを再生成しました") });
    });
  }

  private get webdavUrl(): string {
    if (!this.company) return '';
    return `https://dav.sato.smashoku.com/${this.company.id}/`;
  }
}
