import RepositoryBase, {IFindOption} from "@/repositories/repository-base";
import PartnerFactoryIntraCustomValueSettingEntity from "@/entities/partner-factory-intra-custom-value-setting-entity";

export class PartnerFactoryIntraCustomValueSettingRepository extends RepositoryBase<any> {
  protected companyId!:number;

  public constructor(companyId) {
    super();
    this.companyId = companyId;
    this.endpoint = `companies/${companyId}/partner-factory-intra-custom-value-settings`
  }

  public async index(): Promise<PartnerFactoryIntraCustomValueSettingEntity[]>  {
    const res = await this.get(this.endpoint);
    return res.data.map(d => new PartnerFactoryIntraCustomValueSettingEntity(d));
  }
  public async save(data: PartnerFactoryIntraCustomValueSettingEntity[]) {
    const res = await this.put(this.endpoint, data);
    return res.data;
  }
}
